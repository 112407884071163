.mf-overview-container{
    position: relative;
    min-height: 320px;

    .mf-loader{
        top: 38%;
    }

    .growth10k-chart-parent-container{
        .chart-filter-buttons{
            background-color: #F6F6F6;
            height: 40px;
            line-height: 40px;

            ul {
                margin-bottom: 0;
                padding-left: 0;
                list-style: none; 

                li{
                    display: inline-block;
                    padding-right: 25px;

                    &.lbl-growth-of10k{
                        padding: 0 15px 0px 0px;
                        font-size: 14px;
                        font-weight: 600;
                        outline: none;
                        float: right;
                        width: auto;
                        color: #1F1F1F;
                    }

                    button {
                        background: transparent;
                        color: #2472B2;
                        border: none;
                        font-family: "Open Sans";
                        padding: 0;
                        font-size: 14px;
                        font-weight: 600;
                        outline: none;
                        height: 40px;
                        width: 40px;

                        &.selected{
                            color: #003057;
                            border-bottom: 2px solid #2472B2;
                        }
                    }
                }
            }
        }

        .growth10k-chart-container{
            img {
                display: none;
                width: 100%;
                border: 0;
                margin-top: 5px;
                &.growth10k-chart-desktop{
                    display: block;
                    height: 320px;
                }
            }            
        }
    }

    .bottom-container{
        margin-top: 5px;

        .date{
            font-size: 12px;
            color: #555B63;
            font-family: "Open Sans";
        }

        .disclosures{
            float: right;
            color: #2472B2;
            font-size: 13px;
            font-weight: 600;
            cursor: pointer;
        }
    }
    @media only screen and (max-width:670px) and (min-width:370px){
        .growth10k-chart-parent-container{
            .chart-filter-buttons{
                ul {
                    li{
                        padding-right: 5px;

                        &.lbl-growth-of10k{
                            font-size: 12px;
                            padding: 0 5px 0 0;
                        }
                    }
                }
            }
        }
    }

    /*IPAD*/
    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        .growth10k-chart-parent-container{
            .growth10k-chart-container{
                img {
                    display: none;
                    &.growth10k-chart-desktop{
                        display: none;
                    }
                    &.growth10k-chart-ipad{
                        display: block;
                    }
                }            
            }
        }
    }

    /*MOBILE*/
    @media only screen and (max-width : 767px) {
        .growth10k-chart-parent-container{
            .growth10k-chart-container{
                img {
                    display: none;
                    &.growth10k-chart-desktop{
                        display: none;
                    }
                    &.growth10k-chart-mobile{
                        display: block;
                    }
                }            
            }
        }
    }
}

 /*MOBILE*/
 @media only screen and (max-width : 768px) {
    .mf-overview-container {
        padding: 15px 20px;
    }
}

/*MOBILE max-width : 375*/ 
@media only screen and (max-width : 375px) {
    .mf-overview-container {
        .growth10k-chart-parent-container{
            .chart-filter-buttons{
                height: 28px;
                line-height: 28px;
                ul {
                    li{
                        padding-right: 2px;
    
                        &.lbl-growth-of10k{
                            padding: 0 5px 0px 0px;
                            font-size: 11px;
                        }
    
                        button {
                            font-size: 12px;
                            height: 28px;
                            width: 28px;
                        }
                    }
                }
            }
        }
    }
}