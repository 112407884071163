.search-symbol-page-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  z-index: 99;
  overflow: hidden;

  div>form>label {
    width: 100%;
  }

  div>form>label>input {
    border: none !important;
    border-bottom: 1px solid #555B63 !important;
    font-size: 16px;
  }

  div>form {
    height: 88%;
    position: absolute;
    width: 100%;
  }

  .close-btn {
    position: fixed;
    bottom: 0;
    background: #2472B2;
    color: #FFFFFF;
    width: 100%;
    border-radius: 0;
   // padding: 8px;
    text-align: center;
    z-index: 199;
  }

  form[data-display-add-icon="true"] > div {
    max-height: 100% !important;
    box-shadow: none;
  }
}