@import "../../_variables";

// * {
// 	-webkit-touch-callout:none;               
// 	-webkit-text-size-adjust:none;          
// 	-webkit-tap-highlight-color:rgba(0,0,0,0);
// 	-webkit-user-select:none;                 
// }

html{
	height: 100% !important;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
}

body {
	background-color: $color-stifel-intyce-white;
	font-family: sans-serif;
	margin: 0;
	padding: 0;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	height: 100% !important;
  overflow: auto !important;
  -webkit-overflow-scrolling: touch !important;
}
body::-webkit-scrollbar {
  width: 0px;
}

.container {
	padding: 0 $spacing-10;
	margin: 0 auto;
}

/**
 * Media queries
 */
@media screen and (min-width: 35.5em) {
	.container {
		padding: 0 $spacing-22;
	}
}

@media screen and (min-width: 48em) {
	.container {
		padding: 0 $spacing-30;
	}
}

@media screen and (min-width: 64em) {
	.container {
		padding: 0 $spacing-45;
	}
}

@media screen and (min-width: 80em) {
	.container {
		padding: 0 $spacing-60;
	}
}

/* pure-hidden-xs */
@media screen and (max-width:567.98px) {
    .pure-hidden-xs{display:none}
}
/* pure-hidden-sm */
@media screen and (min-width:568px) and (max-width:767.98px) {
    .pure-hidden-sm{display:none}
}
/* pure-hidden-md */
@media screen and (min-width:768px) and (max-width:1023.98px) {
    .pure-hidden-md{display:none}
}
/* pure-hidden-lg */
@media screen and (min-width:1024px) and (max-width:1279.98px) {
    .pure-hidden-lg{display:none}
}
/* pure-hidden-xl */
@media screen and (min-width:1280px) {
    .pure-hidden-xl{display:none}
}
// /* show corousel in header for mobile for 768px */
// @media only screen and (max-width:940px) { //} and (orientation:portrait) {
//     .hide-corousel-for-mobile{display:none}
// }
/* show corousel in header for mobile  */
@media screen and (max-width:1219px)  {
    .hide-corousel-header{display:none}
}
/* show corousel in header for desktop and tablet*/
@media screen and (min-width:1220px) {
    .hide-static-header{display:none}
}

@media screen and (max-width:567px) {
    .pure-u-xs-24-24{width:100%}
}
