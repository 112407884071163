 
.news-article-parent-container {
 
    position: absolute;
    background-color: #FFF;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 5px;
   
    .header-container{
        padding: 20px 0 15px 25px;
        position: absolute;
        top: 0;
        background: #ffff;
        width: 100%;
        position: relative;
        border-bottom: 2px solid #E6E7E8;

        .back-btn{
            font-size: 16px;
            color: #2472B2;
            font-weight: 600;
            display: flex;
            background: transparent;
            border: none;
            outline: none;
            padding: 0;
            cursor: pointer;
            position: relative;
            z-index: 9999;
            font-family: "Open sans";
            
            .icon-back{
                width: 18px;
                height: 18px;
                position: relative;
                top: 1px;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAERSURBVDhPY/hPJTBqEGFAlEGvPn3/f+nR+/9///6DimACggZ9/v77f9z0Y/+D+g/9f/ruK1QUE+A06N+/f//ff/35P2Peyf/qJZv/t2288v/bz99QWUyA06AvQJdUrrzwX7N08/+KFRf+v/vyEyqDHWA1COiY/20brvzXKtvyv2TZuf8fv/2CyuAGWA2af/Duf5WiTf8jpxz5//Ljd6gofoDVoHP33/337Nr/36559/8dF59BRfEDnF4DRbdDy57/5nU7/h+99RoqgxvgDGxQrG09/wRsEAhffAhMRyAbcACcBoHAH2AC3HDm8X+Tmu3/Y6cd/f8KT3jhNQgEQIbtuvzs/6QdN8hLR6SCYWvQ//8ACS12hDU6b4AAAAAASUVORK5CYII=);
            }
        }
    }

    .news-article-container{
        overflow-y: auto;
        overflow-x: hidden;
        word-wrap: break-word;
        height: 86vh;
        background-color: #FFFFFF;

        .content-container{
            width: 100%;
            background-color: #FFFFFF;
            height: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: initial;

            &[data-articleid*="2993-"], &[data-articleid*="1-"] {
                .image-container {
                    position: relative;
                    padding: 0px;
                    img {
                        max-height: 350px;
                        object-fit: cover;
                    }
                    .image-banner-top-text {
                        background: rgba(8, 31, 49, .7);
                        position: absolute;
                        bottom: 0px;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 20px 30px;
                        margin-bottom: 19px;
    
                        &[data-image-credit="false"] {
                            margin-bottom: 4px;
                        }
                        .provider-and-date-time, .author {
                            color: white;
                            display: inline-block;
                            width: 50%;
                        }
    
                        .author {
                            text-align: right;
                        }
    
                        .title {
                            color: #fff;
                            margin: 12px 0 0 0;
                            font-size: 28px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        }
                    }
                }

                .image-credit-container {
                    padding-left: 30px;
                }
            }

            .image-container{
                padding: 0 25%;
                box-sizing: border-box;

                img {
                    width: 100%;
                    margin-top: 1px;
                }

                .image-credit-container{
                    font-size: 13px;
                    color: #555B63;
                }                
            }

            .publisher-info {
                margin: 10px 0 0 30px;
                .provider {
                    font-size: 13px;               
                    font-weight: 600;
                    color: #555B63;
                    margin-right: 10px;
                }

                .date {
                    font-size: 13px;
                    color: #555B63;
                }
            }

            .title{
                font-size: 30px;
                color: #1F1F1F;
                box-sizing: border-box;
                margin: 10px 30px 10px 30px;

                &.no-image{
                    margin: 20px 30px 10px 30px;
                }
            }

            .article{
                margin: 15px 30px 50px 30px;
                font-size: 16px;
                color: #1F1F1F;

                pre {
                    white-space: unset;
                    text-align: justify;
                    margin: 5px 0px;
                }

                &[data-is-news-of-the-day="true"] {
                    pre {
                        font-weight: 600;
                    }
                }
            }

            .NewsContainer {
                font-size: 16px;
                color: #1F1F1F;
                .content{
                    max-width: 100%;
                    }
                    .leftShift {
                        float: left;
                        // width:100%;
                        // height:20%;
                    }
                    .rightShift {
                    float: right;
                    }
                    .imageSize {
                        max-width: 350px;
                        max-height: 200px;
                        padding: 10px 10px 10px 10px;    
                        // width: 100%;
                        // height: 20%;
                    }
                    .videoSize{
                        max-width: 350px;
                        max-height: 200px;
                        padding: 10px 10px 10px 10px;    
                    }
                    .firstParagraph {
                        color: #1F1F1F;
                        font-weight: bold;
                    }
                    .paraPadding{
                        padding: 0px 10px 0px 10px;
                    }
                    .image-credit{
                        font-size: 13px;
                        color: #555B63;
                    }
                    .video {
                        padding-right: 20px;
                    }
                    .imageSpacing{
                        padding: 10px 20px 10px 0px;
                    }
                    .creditPadding {
                        padding-left: 10px;
                    }

                    // carousel style updates
                    .carousal-news{
                        width: 300px;
                        padding: 0 10px 0 0;
                    }
                    
                }
        }
    }

    .user-type-indicator{
        position: relative;
        right: 20px;
        bottom: 0;
        width: 100%;
    }
}

.news-article-page-container {
    background-color: #FFF;
    height: 100%;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: 0;
    position: absolute;

    .header-container{
        background: #2472B2;
        margin: 0;
        padding: 15px 0 15px 35px;
        border-radius: 0;
        width: 100%;
        border-bottom: none;
        box-sizing: border-box;

        .back-btn{
            font-size: 14px;
            color: #FFFFFF;
            font-weight: 600;
            display: flex;
            background: transparent;
            border: none;
            outline: none;
            padding: 0;
            cursor: pointer;
            position: relative;
            z-index: 9999;
            font-family: "Open sans";

            .icon-back-arrow{
                display: inline-block;
                width: 8px;
                height: 8px;
                margin-right: 5px;
                border-top: 2px solid #FFFFFF;
                border-right: 2px solid #FFFFFF;
                transform: rotate(-135deg);
                margin-top: 5px;
            }
        }
    }
}

// /*IPAD*/
// @media only screen and (min-width : 768px) and (max-width : 1024px) {
//     .rightShift {
//         float: none !important;
//         margin: auto !important;
//         // width: 100% !important;
//         text-align: center;
//       }
//       .leftShift {
//         float: none !important;
//         margin: auto !important;
//         // width: 100% !important;
//         text-align: center;
//       }
//       .imageSize {
//         max-width: 80% !important;
//         max-height: 25% !important;
//         margin: auto !important;
//         padding: 5% !important;
//         width: 80% !important;
//       }
//       .videoSize{
//         max-width: 80% !important;
//         max-height: 25% !important;
//         margin: auto !important;
//         padding: 5% !important;   
//     }
//          // carousel style updates
//          .carousal-news{
//             max-width: 80% !important;
//             max-height: 25% !important;
//             width: 90% !important;
//             margin: auto !important;
//             padding: 5% !important;
//         }
// }

 /*MOBILE*/
 @media only screen and (max-width : 767px) {
    .news-article-parent-container {
        .header-container{
            padding: 15px 0 15px 25px;
            border-bottom: none;
        }

        .news-article-container{
            .header-container{
                margin: 15px 0 15px 15px;               
            }
        
            .content-container{
                &[data-articleid*="2993-"], &[data-articleid*="1-"] {
                    .image-container {
                        img {
                            height: 170px;
                        }
                        
                        .image-banner-top-text {
                            position: absolute;
                            background: rgba(8, 31, 49, .5);
                            width: 100%;
                            height: auto;
    
                            .provider-and-date-time, .author {
                                color: white;
                                display: block;
                                width: 100%;
                            }
        
                            .author {
                                text-align: left;
                                color: #E6E7E8;
                                font-size: 12px;
                                margin-top: 2px;
                            }
        
                            .title {
                                color: #fff;
                                margin: 12px 0 0 0;
                                font-size: 25px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 100%;
                            }
                        }
                    }
                }

                .image-container{
                    margin: 0 20px;
                    padding: 0;
                    img{
                        width: 100%;
                        margin-top: 1px;
                        height: 170px;
                    }                   
                }

                .publisher-info {
                    margin: 10px 0 0 20px;                    
                }
        
                .title{
                    margin: 15px 20px 10px 20px;
                    font-size: 24px;

                    &.no-image{
                        margin: 15px 30px 10px 20px;
                    }
                }
        
                .article{
                    margin: 15px 20px 50px 20px;
                    pre {
                        white-space: unset;
                        text-align: justify;
                        margin: 5px 0px;
                    }
                }
            }
        }

        .rightShift {
            float: none !important;
            width: 100% !important;
          }
          .leftShift {
            float: none !important;
            width: 100% !important;
          }
          .imageSize {
            max-width: 100% !important;
            max-height: 100% !important;
            width: 100% !important;
          }
          .videoSize{
            max-width: 100% !important;
            max-height: 100% !important;
            width: 100% !important;   
        }
          // carousel style updates
         .carousal-news{
            max-width: 100% !important;
            max-height: 100% !important;
            width: 95% !important;
            padding-left: 10px !important;
        }
    }
}