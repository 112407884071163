.tbl-holdings{
    min-height: 100px;
    width: 100%;
    font-family: "Open Sans";

    .no-data{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #7d7d7d;
        text-align: center;
        font-size: 25px;
        min-height: 100px;
    }

    .header {
        font-size: 13px;
        color: #555B63;
        font-weight: 600;

        .symbol{
            height: auto !important;
        }
    }

    .header-1 {
        border-bottom: 1px solid #AAADB1;
        padding: 8px 0 5px 0;
        box-sizing: border-box;

        .range-52week-low-high{
            padding: 0 !important;
            .low{
                text-align: left !important;
            }

            .high{
                float: right;
                text-align: right !important;
            }
        }
    }

    .row > div {
        padding: 0px 3% 0 0;
        box-sizing: border-box;
    }

    .row {
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        
        .symbol{
            width: 20%;
            padding-right: 5px;
            cursor: pointer;
            height: 55px;
            padding-left: 4px;
            box-sizing: border-box;
            
            .name{
                font-size: 14px;
                color: #2472B2;
                font-weight: 600;
                line-height: 16px;
                cursor: pointer;
                display: inline-block;
                margin-top: 12px;
            }

            .company{
                font-size: 13px;
                color: #555B63;
                line-height: 16px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .last{
            width: 10%;
            text-align: right;
        }

        .change{
            width: 10%;
            text-align: right;
            position: relative;

            .price-percent{
                width: 90%;
                display: inline-block;
                padding-right: 3px;
                box-sizing: border-box;
            }
            .icon{
                width: 10%;
                display: inline-block;
                position: relative;
                text-align: center;
                padding-top: 10px;
                box-sizing: border-box;
                top: -10px;
            }
        }

        .volume{
            width: 10%;
            text-align: right;
            padding: 0;
        }

        .bold{
            font-weight: 600;
            font-size: 13px;
            color: #1F1F1F;
        }

        .range-52week{
            text-align: center;
            width: 40%;
            padding: 0 0 5px 0;
            border-bottom: 1px solid #AAADB1;
        }

        .action{
            width: 5%;
            padding: 0px;
            text-align: center;

            .del-icon{
               display: none;
            }
        }

        .range-52week-low-high{
            width: 40%;
            padding: 0px;
            box-sizing: border-box;

            .low{
                width: 20%;
                display: inline-block;
                text-align: right;
            }

            .high{
                width: 20%;
                display: inline-block;
                text-align: left;
            }

            .slider-container{
                width: 60%;
                box-sizing: border-box;
                padding: 0 10px;
            }
        }

        .col-header{
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none; 
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;          
        }
    }

    .data-row{
        padding: 0;
        border-bottom: 1px solid #E6E7E8;
        font-size: 14px;
        color: #1F1F1F;
        &:hover,&:focus-within  {
            background: rgba(227,237,245,0.50);

            .del-icon{
                display: inline-block;
                cursor: pointer;
             }
        }
    }
}

/*IPAD*/
@media only screen and (max-width : 1024px) {
    .tbl-holdings{
        .row {
            .symbol{
                width: 15%;
            }

            .change{
                width: 15%;
                padding-right: 2%;
            }
    
            .action{
                width: 3%;
            }
    
            .volume{
                width: 13%;
            }
            
            .range-52week-low-high{
                .slider-container{
                    width: 50%;
                }

                .low{
                    width: 25%;
                }
    
                .high{
                    width: 25%;
                }
    
            }
        }
    }
}

@media only screen and (max-width : 1000px) {
    .tbl-holdings{
        .row {
            .action{
                .del-icon{
                   display: inline-block;
                }
            }
        }
    }
}

/*MOBILE*/
@media only screen and (max-width : 768px) {
}
