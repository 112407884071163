.tbl-holdings{
    min-height: 100px;
    width: 100%;
    font-family: "Open Sans";

    .no-data{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #7d7d7d;
        text-align: center;
        font-size: 25px;
        min-height: 100px;
    }

    .header {
        font-size: 13px;
        color: #555B63;
        font-weight: 600;
    }

    .header-1 {
        border-bottom: 1px solid #AAADB1;
        padding: 8px 0 5px 0;
        box-sizing: border-box;
    }

    .row > div {
        padding: 0px 3% 0 0;
        box-sizing: border-box;
    }

    .row {
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .symbol{
            .name{
                font-size: 14px;
                color: #2472B2;
                font-weight: 600;
                line-height: 16px;
                cursor: pointer;
                display: inline-block;

                white-space: nowrap;
                max-width: 95%;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
            }

            .company{
                font-size: 13px;
                color: #555B63;
                line-height: 16px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .last{
            text-align: right;
        }

        .change{
            text-align: right;
            position: relative;
            padding-right: 5%;

            .price-percent{
                width: 90%;
                display: inline-block;
                padding-right: 3px;
                box-sizing: border-box;
            }
            .icon{
                width: 10%;
                display: inline-block;
                position: relative;
                height: 38px;
                text-align: center;
                padding-top: 10px;
                box-sizing: border-box;
                top: -10px;
            }
        }

        .volume{
            text-align: right;
            padding: 0;
        }

        .bold{
            font-weight: 600;
            font-size: 13px;
            color: #1F1F1F;
        }

        .range-52week{
            text-align: center;
            padding: 0 0 5px 0;
            border-bottom: 1px solid #AAADB1;
        }

        .range-52week-low-high{
            padding: 0;
            display: flex;
            flex-direction: column;
            flex-flow: wrap;
            justify-content: space-between;

            .low{
                width: 15%;
                display: inline-block;
                text-align: left;
            }

            .high{
                width: 15%;
                display: inline-block;
                text-align: right;
            }

            .slider-container{
                width: 60%;
            }
        }

        .col-header{
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none; 
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;          
        }
    }

    .data-row{
        padding: 5px 0;
        border-bottom: 1px solid #E6E7E8;
        font-size: 14px;
        color: #1F1F1F;
    }

    &.tbl-basic{
        .row {
            .symbol{
                width: 35%;
            }

            .last{
                width: 25%;
                padding: 0px;
            }
    
            .change{
                width: 40%;
                padding-right: 0;

                .price-percent {
                    :first-child {
                        margin-right: 3px;
                    }
                }
                .icon{
                    top: 0;
                }
            }
        }
        .data-row {
            .change {
                display: flex;
                flex-direction: column;
                flex-flow: wrap;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &.tbl-performance{
        .row {
            .symbol{
                width: 22%;
            }

            .volume{
                width: 22%;
            }
    
            .range-52week {
                width: 56%;
                text-align: center;
                padding: 0 0 5px 0;
                border-bottom: 1px solid #AAADB1;
            }
    
            .range-52week-low-high{
                width: 56%;
                padding: 0;
                display: flex;
                flex-direction: column;
                flex-flow: wrap;
                justify-content: space-between;
    
                .low-high-container {
                    .low{
                        width: auto;
                    }
        
                    .high{
                        width: auto;
                    }
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
    
                .slider-container{
                    width: 100%;
                }
            }
        }

        .data-row {
            padding: 8px 0;
        }
    }

    &.tbl-fundamentals{
        .row {
            .symbol{
                width: 35%;
            }

            .ratioPE{
                width: 30%;
                text-align: right;
            }

            .dividend {
                width: 35%;
                text-align: right;
            }
        }

        .data-row {
            padding: 10px 0;
        }
    }
}
