.news-list-container{
    margin-top: 10px;
    padding: 0 15px 15px 15px;
    background: #FFFFFF;
    border: 1px solid #E6E7E8;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    
    &.loading{
        padding: 0;
    }

    .pagination{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #555B63;
        position: relative;
        top: 6px;
        
        .lbl-records-count{
            margin-top: 7px;
        }
        .action-buttons{
            -webkit-touch-callout: none;
            -webkit-user-select: none; 
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;        
            
            .icon{
                width: 25px;
                height: 25px;
                display: inline-block;
                top: 7px;
                position: relative;
                cursor: pointer;
            }
            .icon-left-disabled{
                right: 2px;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAZCAYAAADe1WXtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADwSURBVEhL7ZTZDsIgFET9/w+1dK9p0W6AjFJTdmL1wcST8AL1ZDL3xpP4An9pOsMwiIwQ0badunGTLO37QZwz8jrzsqgXm6iUcy4TUk1I8uJx7yMoxQ8pvVrCJZASBKWU6gkzkot1XdWrH6+0l0PRhbLHeVavYZxScyg4qUKgSZ9DMRPmUhju0ESTMsZFthPiTFN6wg0raV03mhTTZoH1cWF1CnHTtLaYMfVFHOegIO66iyYuijJpnYBTCpzisjq2pwBi/HnsxWVVR6sISoGrYyTGvY+odMMUh1YtWfqoQnX8saQAots4Hu/0HX5FKsQdC7gX7Qy0vaQAAAAASUVORK5CYII=)
            }

            .icon-left-active{
                right: 2px;
                top: 9px;
                transform: rotate(180deg);
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAbCAYAAACX6BTbAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFeSURBVEhL7ZPNSgJRFMd7mMoKWgRuiuhReojMCCxQgqBdu55BAiFnYkprW0GQmBMtJCjyY7Q0U3SsjJNnuqZ3xvtFRBt/cDdzht+Z879nxuAPGcmH8j/y1lsHlvfOwL9hwHn2iTxVgynXrnIwvhJ3zsyaDslMkVTkYcozjzXwrWpUg1PTIlU5uJlf3lUcaa/BdFBXikh4oan7KkwG+hPgNFmrQap8hHLkpBuHu4HMBFJyRE/lwTfQACMS3YG0HMENGpzA2SKTvUVKciR68fAjx7MQTpCKFyW5VbNhdv2Qkm/up0nVi7S89GrDfPiYEm8fmPDR+SRveJGSF19asLSVpMS7xi28c8SIUF6utynxRPfsaDfcL+7BlZfrNixGEn1xIA6R2DWpimHKnxttmAsZ1BeriBGm3L1yGIUqTHm+2oSp4PcPg5cnk7Ebbua5ShOO0gXhVrDgyn/LSD4EgC88+jDt4UJw2AAAAABJRU5ErkJggg==)
            }

            .icon-right-disabled{
                left: 0px;
                transform: rotate(180deg);
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAZCAYAAADe1WXtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADwSURBVEhL7ZTZDsIgFET9/w+1dK9p0W6AjFJTdmL1wcST8AL1ZDL3xpP4An9pOsMwiIwQ0badunGTLO37QZwz8jrzsqgXm6iUcy4TUk1I8uJx7yMoxQ8pvVrCJZASBKWU6gkzkot1XdWrH6+0l0PRhbLHeVavYZxScyg4qUKgSZ9DMRPmUhju0ESTMsZFthPiTFN6wg0raV03mhTTZoH1cWF1CnHTtLaYMfVFHOegIO66iyYuijJpnYBTCpzisjq2pwBi/HnsxWVVR6sISoGrYyTGvY+odMMUh1YtWfqoQnX8saQAots4Hu/0HX5FKsQdC7gX7Qy0vaQAAAAASUVORK5CYII=)
            }

            .icon-right-active{
                left: 4px;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAbCAYAAACX6BTbAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFeSURBVEhL7ZPNSgJRFMd7mMoKWgRuiuhReojMCCxQgqBdu55BAiFnYkprW0GQmBMtJCjyY7Q0U3SsjJNnuqZ3xvtFRBt/cDdzht+Z879nxuAPGcmH8j/y1lsHlvfOwL9hwHn2iTxVgynXrnIwvhJ3zsyaDslMkVTkYcozjzXwrWpUg1PTIlU5uJlf3lUcaa/BdFBXikh4oan7KkwG+hPgNFmrQap8hHLkpBuHu4HMBFJyRE/lwTfQACMS3YG0HMENGpzA2SKTvUVKciR68fAjx7MQTpCKFyW5VbNhdv2Qkm/up0nVi7S89GrDfPiYEm8fmPDR+SRveJGSF19asLSVpMS7xi28c8SIUF6utynxRPfsaDfcL+7BlZfrNixGEn1xIA6R2DWpimHKnxttmAsZ1BeriBGm3L1yGIUqTHm+2oSp4PcPg5cnk7Ebbua5ShOO0gXhVrDgyn/LSD4EgC88+jDt4UJw2AAAAABJRU5ErkJggg==)
            }

            input[type="text"]{
                background: #FFFFFF;
                border: 1px solid #AAADB1;
                border-radius: 3px;
                width: 38px;
                height: 25px;
                color: #1F1F1F;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
                padding: 0 5px;
            }
        }
    }
}


/*MOBILE*/
@media only screen and (max-width : 768px) {
    .news-list-container{
        width: 100%;
        padding: 0 15px;
        margin: 0 0 20px;
        position: relative;
        min-height: 100px;
        
        .pagination{
            display: flex;
            flex-direction: column;
            text-align: center;
            top: 0;
            padding: 10px 0;

            .lbl-records-count{
                margin-top: 0;
                top: 0;
            }
        }
    }
}