@import "../../../pattern-library/src/styles/_variables";
@import "../../../pattern-library/src/styles/_typography";
@import "../../styles/main.scss";

.stock-overview-container {
  font-family: "Open sans";
  min-height: 320px;
  .chart-view {
    min-height: 250px;
  }
  .stock-loader {
    top: 38%;
  }
  .bottom-container {
    margin-top: 5px;
    .date {
      font-size: 12px;
      color: #555b63;
    }
  }
  .companySummary {
    padding-bottom: 10px;
  }
  .companyResearchDiv {
    display: table-cell;
    width: 350px;
    padding-left: 15px;
    padding-bottom: 5px;
    @media only screen and (max-width: 670px) and (min-width: 370px) {
      width: 335px;
      height: 286px;
      padding-top: 15px;
    }
  }

  .link-font {
    padding-left: 7px;
    font-weight: $font-weight-semi-bold;
    color: #2472b2;
    font-size: 12px;
  }
  .research-head {
    width: 350px;
    height: 44px;
  }

  .research-font {
    height: 19px;
    font-family: Open sans;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
    color: #003057;
    padding-bottom: 5px;
    @media only screen and (max-width: 670px) and (min-width: 370px) {
      font-family: Open sans;
      font-weight: $font-weight-semi-bold;
      font-size: 14px;
      color: #003057;
    }
  }
  .float-left {
    float: left;
  }
  .research-header {
    width: 350px;
    padding-bottom: 5px;
    height: 19px;
    font-weight: $font-weight-semi-bold;
    color: #003057;
    display: inline-block;
  }

  .target-Contain {
    font-family: Open sans;
    padding-left: 12px;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
    height: 42px;
    width: 63px;
    color: #555b63;
  }

  .rating-Context {
    font-family: Open sans;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
    height: 42px;
    width: 63px;
    color: #555b63;
  }
  .rating-Value {
    display: block;
    overflow-y: hidden;
    font-family: Open sans;
    font-size: 20px;
    color: #231f20;
  }

  .target-Value {
    display: block;
    overflow-y: hidden;
    font-family: Open sans;
    font-size: 20px;
    padding-left: 12px;
    color: #231f20;
  }

  .companySummaryText {
    // display: inline-block;
    padding-top: 10px;
    padding-bottom: 1.7%;
    font-size: 14px;
    color: #1f1f1f;
    @media only screen and (max-width: 670px) and (min-width: 370px) {
      font-size: 16px;
    }
  }

  .divider1 {
    width: 350px;
    height: 2px;
    border-bottom: 1px solid #e6e7e8;
    @media only screen and (max-width: 670px) and (min-width: 370px) {
      width: 335px;
      height: 2px;
      padding-left: 2px;
    }
  }
  .divider {
    display: block;
    width: 95%;
    padding-left: 2px;
    padding-right: 2px;
    height: 2px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e6e7e8;
    @media only screen and (max-width: 670px) and (min-width: 370px) {
      width: 95%;
      height: 2px;
      padding-left: 0px;
      padding-top: 1.5%;
    }
  }

  .targetPrice {
    width: 69px;
    height: 17px;
    color: #999;
    padding-top: 5px;
    font-family: Open sans;
    font-style: normal;
    display: inline-block;
    font-size: 12px;
    padding-right: 1px;
    position: absolute;
    @media only screen and (max-width: 670px) and (min-width: 370px) {
      top: 12%;
      left: 20%;
      padding-left: 2%;
    }
  }

  .rating {
    width: 63px;
    height: 42px;
    position: absolute;
    color: #999;
    padding-top: 0.5%;
    font-family: Open sans;
    font-style: normal;
    display: inline-block;
    font-size: 12px;
    padding-right: 1px;
    @media only screen and (max-width: 670px) and (min-width: 370px) {
      top: 12%;
      left: -15%;
    }
  }

  .summaryContainer {
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 798px;
    height: 19px;
    width: 133px;
    font-family: OpenSans;
    color: #003057;
    font-size: 14px;
    @media only screen and (max-width: 670px) and (min-width: 370px) {
      padding-top: 23%;
      padding-bottom: 2%;
    }
  }

  .research {
    width: 200px;
    margin-left: 622px;
    position: absolute;
    padding-top: 15px;
    @media only screen and (max-width: 670px) and (min-width: 370px) {
      top: 2%;
      left: -24%;
      padding-bottom: 2%;
      display: block;
      width: 100%;
      padding-top: 2%;
      border-bottom: 1px solid #ccc;
      padding-right: 30%;
    }
  }

  .stifel-research-icon {
    outline: none;
    height: 15px;
    width: 16px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAI5SURBVDhP1dNLaxNRFAdwP8M0j5lEmzSPZiYzadI0qW2ajsbGSm3T0qrU0kVRJIoLN3bVjV2pGxWsoC4U3bgQ3fgAqyi6FimIQhHxhYiKG/sF/t6Zc+eVjDu78A9n++Oce8/Zgk3I5qJbO2OIdsZZJRCNJRGNpxCJdyPSlWGlQEpkISU1iMkcxFQeYroX4e4+ViWEMv1cobhQBsbcYLoNlDxg0QZD8gBXKDZqdxhzgzKyxQqWlk/j/MoVrFy9jun5Iwin3eB2BOUKVygO6gGNsWWkc2U8efYCxaE6ople1CYOYPnsBQ8YkgcRVIa4QnGhXtAY+eiJRVxi3dHIBbPDUq3RBgazw1yh2GgrKCVUHGPoq7XX2KaUXSOXWXnBQFbnCsWFWqDxMar5MXJfFT9+/sLD1afoKgz7gFUTDKg7uUJx0BZQTPWwKmBq7jB+b2zg7fo7lEamfcGAtosrFBuVzNXxgmG+i/r4LD58+oKv375D0ycZ6gU7tDpXKA7aAlrLTSP3Q6uO4/3Hz7h26y7r0gJrJtiR280VioOaoPdaIgp7P77cQaWC5slTeLn2hkDVAEdMUOgZ5QrFRv3Ob2bhuA0aI882F3H73qoLNLocZegerlAc1Oee79x/hLGDTYiaDlWfwoPHzzHYWGgDhfwYVygOaoCe8xtAfd8hnLt8g9VNLJ25CK223xcU8nu5QrFRv3t2lntHy8jGOzqgkJ/gCsVBLbDtWiyQrc5fQKHQ4ArFRv9l/hcU+AO/HII78VAdqAAAAABJRU5ErkJggg==");
    background-repeat: no-repeat;
    display: inline-block;
    position: relative;
  }
  /*-- our cleafix hack -- */
  .row:before,
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .containerCompanyHeader {
    height: 44px;
  }

  .containerMain {
    display: table;
    padding-top: 15px;
    width: 73.8%;
    height: 25%;
    clear: both;
    @media screen and (min-width: 320px) and (max-width: 768px) {
      width: 100%;
      // display: flex;
      // flex-direction: column-reverse;
    }
  }

  [class*="col-"] {
    float: left;
    min-height: 1px;
    width: 16.66%;
    /*-- our gutter -- */
    padding: 12px;
    background-color: #ffdcdc;
  }
  .paddingTop10px {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .companyblockFont {
    font-family: Open sans;
    font-style: normal;
    color: #333;
    display: inline-block;
    font-size: 12.5px;
  }

  .font-company {
    font-weight: $font-weight-semi-bold;
  }

  .col-1 {
    width: 16.66%;
  }
  .col-2 {
    width: 33.33%;
  }
  .col-3 {
    width: 50%;
  }
  .col-4 {
    width: 66.66%;
  }
  .col-5 {
    width: 83.33%;
  }
  .col-6 {
    width: 100%;
  }

  .company-header {
    font-weight: bold;
    border-bottom: 1px solid #cccccc;
    @media screen and (min-width: 320px) and (max-width: 768px) {
      border-bottom: none;
    }
  }
}

/*MOBILE*/
@media only screen and (max-width: 768px) {
  .stock-overview-container {
    padding: 15px 20px;
  }
}

@media screen and (min-device-width: 1580px) {
  .stock-overview-container {
    .companySummaryText {
      font-size: 12px !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .companySummary {
      padding-bottom: 5px !important; 
    }
    .bottom-container {
      margin-top: 0px !important;
    }
  }
}
