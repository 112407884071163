.create-watchlist-card-container {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;

  .create-watchlist-button {
    border: 2px solid #2472B2;
    border-radius: 3px;
    padding: 10px;
    margin-top: 20%;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    color: #2472B2;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;

    span:nth-child(1) {
      margin-right: 5px;
    }
  }

  .disable {
    border: 1px solid #AAADB1;
    color: #AAADB1;
    cursor: default;
  }

  .max-limit-watchlist {
    font-family: 'Open Sans';
    font-size: 1em;
    font-weight: 600;
    line-height: 1.8em;
    color: #2472B2;
    letter-spacing: 0;
    text-align: center;
    margin-top: 2%;
  }
}
