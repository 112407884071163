.manage-holding-container {
  position: fixed;
  top: 50px;
  left: 0;
  height: 100%;
  width: 100%;
  background: #F7F7F7;
  z-index: 99;
  box-sizing: border-box;
  font-family: "Open sans";
  overflow-x: hidden;
  overflow-y: hidden;

  .watchlist-name-header {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 20px;
    color: #1F1F1F;
    letter-spacing: 0;
    background: #FFFFFF;
    padding: 0 15px;
    border-bottom: 1px solid #E6E7E8;
    height: 10%;

    .watchlist-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
        }

    .watchlist-name-1 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      }

    .edit-watchlist-name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: wrap;
      height: 100%;
      input {
        color: #555B63;
        background: #FFFFFF;
        width: 100%;
        box-sizing: border-box;
        border: none;
        outline: none;
      }

      .already-exists {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        color: #CB223C;
      }
    }
  }

  .holding-details-container {
    border: 1px solid #E6E7E8;
    margin: 2.5% 2%;
    width: 96%;
    height: 68%;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #FFFFFF;
    padding: 0 10px;
    overflow-y: auto;

    .holding-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E6E7E8;
      padding: 10px 0;

      .symbol {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px;
        color: #1F1F1F;
        letter-spacing: 0;
        cursor: pointer;
      }

      .name {
        font-family: 'Open Sans';
        font-size: 13px;
        color: #555B63;
        line-height: 16px;
      }
    }
  }

  .action-button-container {
    border-top: 1px solid #838384;
    border-bottom: 1px solid #E6E7E8;
    border-left: 1px solid #E6E7E8;
    border-right: 1px solid #E6E7E8;
    border-radius: 0 0 4px 4px;
    padding: 0 10px;
    position: fixed;
    bottom: 0;
    width: 96%;
    height: 10%;
    background: #FFFFFF;
    margin: 0 2%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2.5%;

    .done-btn {
      background: #003057;
      box-shadow: 1px 2px 3px 0 rgba(85, 91, 99, 0.20);
      border-radius: 3px;
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
      padding: 10px 20px;
      border: 1px solid #003057;
    }

    .cancel-btn {
      font-family: 'Open Sans';
      font-size: 14px;
      color: #555B63;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid #E6E7E8;
      box-shadow: 1px 2px 3px 0 rgba(85, 91, 99, 0.20);
      border-radius: 3px;
      padding: 10px 20px;
      margin-right: 10px;
    }
  }
}