.watchlist-overview-container {
    position: relative;
    display: block;
    font-size: 16px;
  .watchlist-overview {
    padding: 2% 4%;
    background: #FFFFFF;
    border-bottom: 1px solid #E6E7E8;

    .heading {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 0.875em;
      color: #555B63;
      letter-spacing: 0;
      padding-bottom: 2%;
    }

    .best-worst-container {
      .best, .worst {
        .symbol-info {
          padding-bottom: 5%;
          .symbol {
            display: inline-block;
            font-family: 'Open Sans';
            font-weight: 600;
            font-size: 0.875em;
            color: #2472B2;
            letter-spacing: 0;
            cursor: pointer;
          }
          .company-name {
            font-family: 'Open Sans';
            font-size: 0.750em;
            color: #555B63;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .change-info {
          font-family: 'Open Sans';
          font-weight: 600;
          font-size: 0.9em;
          letter-spacing: 0;
          text-align: right;
          line-height: 2vw;
        }
      }
      display: block;
      width: 100%;
      .best {
        display: inline-block;
        width: 44.5%;
        border-right: 1px solid #E6E7E8;
        padding-right: 5%;
      }
      .worst {
        display: inline-block;
        width: 45%;
        padding-left: 5%;
      }
    }
  }
  .performance-status-container {
    padding: 2% 4% 4% 2%;

    .total-security {
      font-size: 0.750em;
      color: #666666;
      font-weight: 600;
    }
    .performance-status-bar {
      width: 100%;
    }
    .performance-status-bar {
      div {
        display: inline-block;
        height: 10px;
        border-right: 1px solid #FFFFFF;
        box-sizing: border-box;
      }
      .advancers {
        background: #5C8118;
      }
      .unchanged {
        background: #555B63;
      }
      .decliners {
        background: #CB223C;
      }
    }

    .securities-count {
      div {
        display: inline-block;
        width: 33%;
        font-size: 0.875em;
        letter-spacing: 0;
        line-height: 2.4vw;
        span:first-child {
          font-weight: 600;
        }
      }
      .advancers {
        color:  #5C8118; 
        text-align: left;
      }
      .unchanged {
        color: #555B63;
        text-align: center;
      }
      .decliners {
        color: #CB223C;
        text-align: right;
      }
    }
  }
  .empty-list {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 18%;
    flex-direction: column;
    align-items: center;
    .empty-list-text {
      font-family: 'Open Sans';
      font-size: 1.125em;
      line-height: 1.5em;
      color: #555B63;
      letter-spacing: 0;
    }
    .add-security-link {
      font-family: 'Open Sans';
      font-size: 1.000em;
      font-weight: 600;
      color: #2472B2;
      letter-spacing: 0;
      cursor: pointer;
    }
  }
}
