.treasury-yields-container {
  font-family: "Open Sans";
  display: flex;
  flex-flow: wrap;
  padding-left: 25px;
  padding-right: 25px;
  background-color:#081F31;
  
  .Treasury-Yields-header{
    margin: auto;
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 24px;
    color: white;
    width: 100%;
  }
  .header {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 24px;
    color: white;
    width: 100%;
    border-bottom: 2px solid #dadadb;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  .mobile-container {
    width: 100%;
    padding-bottom: 15px;

    .container {
      display: inline-block;
      position: relative;
      background-color: #081F31;
      border: 1px solid #013c6b;
      border-radius: 4px;
      padding: 1.5% 1%;
      margin-right: 2%;

      .content-container {
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0;
        .label {
          color: #e6e7e8;;
          margin-right: 15px;
        }
        .label-values {
          color: #e6e7e8;;
        }
        .values {
          text-align: right;
          line-height: 16px;
        }
        .negative {
          color: #FF3333;
        }
        .positive {
          color: #9ED930;
        }
      }
      .spark-chart {
        margin-top: 15px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .treasury-yields-container {
    .mobile-container {
      .container {
        &:hover {
          border-bottom: 2px solid #013c6b;
          box-shadow: 1px 1px 10px 1px #013c6b;
          -ms-transform: scale(1.03, 1.0); /* IE 9 */
          -webkit-transform: scale(1.03, 1.0); /* Safari 3-8 */
          transform: scale(1.03, 1.0); 
          z-index: 100;
        }
      }
    }
  }
}

/*MOBILE*/
@media only screen and (min-width: 350px) and (max-width: 767px) {
  .treasury-yields-container {
    margin: 0;
    padding: 10px;
    .mobile-container {
      border-radius: 4px;
      padding-left: 0px;
      padding-bottom: 0px;
      .container {
        width: 100%;
        border: none;
        margin: 0;
        padding: 0;
        border-radius: 0;
        .content-container {
          font-size: 16px;
          margin: 0 15px;
          padding: 10px 0;
          border-bottom: 1px solid #e6e7e8;
          .label {
            line-height: 25px;
            color: #e6e7e8;;
          }
          .label-values {
            color: #e6e7e8;;
          }
          .values {
            font-size: 16px;
            float: right;
            line-height: 16px;
          }
        }
        .no-border {
          border-bottom: 0 none #e6e7e8;
        }
      }
    }
  }
}
