.delete-watchlist-container {
  font-family: Open Sans;
  width: 100%;
  height: inherit;
  background: #FFFFFF;

  .delete-watchlist-header {
    font-size: 16px;
    font-weight: 600;
    color: #003057;
    letter-spacing: 0;
    margin: 3% 0;
    word-break: break-word;
  }
  .delete-watchlist-footer {
    text-align: right;
    button {
      background: #FFFFFF;
      box-shadow: 1px 2px 3px 0 rgba(85,91,99,0.20);
      border-radius: 3px;
      font-size: 14px;
      font-weight: 400;
      height: 40px;
      padding: 0 25px;
    }
    button:nth-child(1) {
      border: 1px solid #E6E7E8;
      color: #555B63;
      margin-right: 3%;
    }
    button:nth-child(2) {
      color: #CB223C;
      border: 2px solid #CB223C;
    }
  }
}