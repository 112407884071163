.tbl-market-movers{
    min-height: 100px;
    width: 100%;
    font-family: "Open Sans";

    .no-data{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #7d7d7d;
        text-align: center;
        font-size: 25px;
        min-height: 100px;
    }

    .header {
        font-size: 13px;
        color: #555B63;
        font-weight: 600;
    }

    .header-1 {
        border-bottom: 1px solid #AAADB1;
        padding: 8px 0 5px 0;
        box-sizing: border-box;

        .range-52week-low-high{
            .low{
                text-align: left !important;
            }

            .high{
                text-align: right !important;
            }
        }
    }

    .row > div {
        padding: 0px 3% 0 0;
        box-sizing: border-box;
    }

    .row {
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        justify-content: space-between;
        align-items: center;

        .symbol{
            width: 15%;
            padding-right: 5px;

            .name{
                font-size: 14px;
                color: #2472B2;
                font-weight: 600;
                line-height: 16px;
                cursor: pointer;
            }

            .company{
                font-size: 13px;
                color: #555B63;
                line-height: 16px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .last{
            width: 12%;
            text-align: right;
            padding-right: 1%;
        }

        .change{
            width: 15%;
            text-align: right;
            position: relative;
            padding-right: 2%;

            .price-percent{
                width: 90%;
                display: inline-block;
                padding-right: 3px;
                box-sizing: border-box;
            }
            .icon{
                width: 10%;
                display: inline-block;
                position: relative;
                height: 38px;
                text-align: center;
                padding-top: 10px;
                box-sizing: border-box;
                top: -10px;
            }
        }

        .volume{
            width: 15%;
            text-align: right;
            padding: 0;
        }

        .bold{
            font-weight: 600;
            font-size: 13px;
            color: #1F1F1F;
        }

        .large-Size-Bold{
            font-weight: 600;
            font-size: 15px;
            color: #1F1F1F;
        }

        .range-52week{
            text-align: center;
            width: 38%;
            padding: 0 0 5px 0;
            border-bottom: 1px solid #AAADB1;
        }

        .range-52week-low-high{
            width: 38%;
            padding: 0;
            display: flex;
            flex-direction: column;
            flex-flow: wrap;
            justify-content: space-between;

            .low{
                width: 20%;
                display: inline-block;
                text-align: right;
            }

            .high{
                width: 20%;
                display: inline-block;
                text-align: left;
            }

            .slider-container{
                width: 60%;
                padding: 0 10px;
                box-sizing: border-box;
            }
        }
    }

    .data-row{
        padding: 10px 0;
        border-bottom: 1px solid #E6E7E8;
        font-size: 14px;
        color: #1F1F1F;
    }

    .arrow-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #000;
        top: 12px;
        position: relative;
        right: 5px;
    }

    .slider-container {
        display: inline-block;
        width: 70%;

        .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 9px;
            outline: none;
        }

        input[type=range] {
            opacity: 1 !important;
            -webkit-appearance: none;
        }
        input[type=range]::-webkit-slider-runnable-track {
            height: 9px;
            background: #E6E7E8;
        }
        input[type=range]::-webkit-slider-thumb {
            height: 9px;
            width: 9px;
            background: #2472B2;
            -webkit-appearance: none;
        }
        input[type=range]::-moz-range-track {
            height: 9px;
            width: 96.9px;
            background: #E6E7E8;
        }
        input[type=range]::-moz-range-thumb {
            height: 9px;
            width: 9px;
            background: #2472B2;
            border: 1px solid #2472B2;
            border-radius: 1px;
        }
        input[type=range]::-ms-track {
            height: 9px;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }
        input[type=range]::-ms-fill-lower {
            background: #E6E7E8;
            border: 1px solid #E6E7E8;
            border-radius: 1px;
        }
        input[type=range]::-ms-fill-upper {
            background: #E6E7E8;
            border: 1px solid #E6E7E8;
            border-radius: 1px;
            
        }
        input[type=range]::-ms-thumb {
            height: 9px;
            width: 9px;
            background: #2472B2;
            border: 1px solid #2472B2;
            border-radius: 1px;
        }
    }
}

/*MOBILE*/
@media only screen and (max-device-width : 768px) {
   
}

/*IPAD*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .tbl-market-movers{
        .row{
            .range-52week-low-high{
                .low{
                    width: 25%;
                }

                .high{
                    width: 25%;
                }

                .slider-container{
                    width: 50%;
                }
            }
        }    
    }
}