.market-movers-parent-container{
    width: 100%;
    box-sizing: border-box;
    font-family: "Open sans";
    overflow: hidden;
    padding: 25px;

    .top-header-container{
        padding-bottom: 10px;
        border-bottom: 2px solid #E6E7E8;
        h2{
            font-size: 24px;
            color: #1F1F1F;
            font-weight: lighter;
            margin: 0;
        }
    }
    
    .market-movers-container{
        display: flex;
        width: 100%;
        margin: 10px 0 10px 0px;

        .market-movers{
            flex-grow: 100;
            .ddl-exchanges{
                margin-left: 10px;
            }

            .tbl-market-movers-container{
                background: #FFFFFF;
                min-height: 100px;
                border-radius: 5px;
                margin-top: 10px;
                border: 1px solid #E6E7E8;
                padding: 15px 15px 15px 15px;
            }
        }
    }

    .lbl-hidden{
        font-size: 0px;
    }

    .error-container, .no-data{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #7d7d7d;
        text-align: center;
        font-size: 25px;
        min-height: 100px;
    }

    select {
        padding: 0 10px;
        border: 1px solid #AAADB1;
        border-radius: 3px;
        font-size: 14px;
        color: #2472B2;
        letter-spacing: 0;
        height: 35px;
        width: 180px;
        font-weight: 600;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEXSURBVDhPYxh0gBFEqJZsNf///99KIGfl7V7fcrAMHvD//39G1eItk4DqvRhZGYJudfpeZIJI/FsJJOSBCspUizdNAqvGAUCGqJVsmQNk5fxn+K/07/f/pSBxsEEgl4BoEPj/nyFXpWjzZCgXBTQ0/GcCGjIXaFgSVAiolxGsF+w1EFAt3jwBqCAfygXKME690+ubA+WBDVn6eTPQEIYEqBBId9udXr9qEJMZLAAE744v3yFsFSUIZFpARBjMRKyixd4cW7qdkbEBZMg8XIaAANxFMIDuMkYmhun//zFyAz0dBxXCMAQEMAwCAQxvIgMshoAAVoNAAKthOAwBAZwGgQCKYXgMIQqoFW0uUinelAXlDhnAwAAARo9+EcvNX88AAAAASUVORK5CYII=);
        -webkit-appearance: none;
        background-repeat: no-repeat;
        background-position: right 5px center;
        background-color: #FFF;
    }
    select::-ms-expand {
      display: none;
    }
    select:focus::-ms-value {
      background-color: transparent;
      color: #2472B2;
    }
}


/*MOBILE*/
@media only screen and (max-width : 767.98px) {
    .market-movers-parent-container{
        padding: 10px;

        .top-header-container{
            padding-bottom: 5px;
            h2{
                display: inline-block;
            }

            .exchange-container{
                float: right;
                top: 15px;
                position: relative;
                font-size: 12px;
                color: #555B63;
                font-weight: 600;

                .exchange{
                    cursor: pointer;

                    &.active {
                        color: #2472B2;
                    }
                }

                .divider{
                    border-left: 1px solid #b6b7be;
                    height: 12px;
                    position: relative;
                    font-size: 8px;
                    margin: 4px;
                    top: -1px;
                }
            }
        }

        .rank-type-container{
            margin-bottom: 25px;
            ul {
                padding: 0px;
                margin: 0px;
                border-bottom: 2px solid #E6E7E8;

                li:first-child {
                    margin-left: 0;
                }

                li {
                    display: inline-block;
                    padding: 15px 0 5px 0px;
                    top: 2px;
                    position: relative;
                    font-size: 18px;
                    color: #AAADB1;
                    margin: 0 10px;

                    &.active {
                        color: #2472B2;
                        border-bottom: 2px solid #2472B2;
                    }
                }
            }
        }

        .market-movers-container{
          .market-movers{
            width: 100%;
          }
            .tbl-market-movers-container{     
                padding: 5px 10px 10px 10px !important;
                .tbl-market-movers{
                    .company{
                        font-size: 13px;
                        color: #555B63;
                        border-bottom: 1px solid #E6E7E8;
                        padding: 2px 0 10px 0;
                        max-width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .row{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        align-items: center;
                        margin-top: 10px;

                        .symbol{
                            font-weight: 600;
                            font-size: 14px;
                            color: #2472B2;
                            width: 30%;                            
                        }

                        .last{
                            font-size: 14px;
                            color: #1F1F1F;
                            text-align: right;
                            width: 25%;
                            font-weight: 600;
                        }
                        
                        .large-Size-Bold{
                            font-size: 15px !important;
                            font-weight: 600 !important;
                        }

                        .large-Size-Bold{
                            font-size: 15px !important;
                            font-weight: 600 !important;
                        }

                        .change{
                            width: 45%;
                            text-align: right;
                            font-weight: 600;
                            
                            .price-percent{
                                width: 90%;
                                display: inline-block;
                                padding-right: 3px;
                                box-sizing: border-box;

                                div {
                                    display: inline-block;
                                    padding-left: 2px;
                                    font-size: 16px;
                                    box-sizing: border-box;
                                }
                            }
                            .icon{
                                width: 5%;
                                display: inline-block;
                                position: relative;
                                text-align: center;
                                box-sizing: border-box;
                                top: 0px;
                            }
                        }

                        &.header {
                            font-weight: 600;
                            font-size: 12px;
                            color: #555B63;
                            border-bottom: 1px solid #AAADB1;
                            padding-bottom: 5px;
                            box-sizing: border-box;

                            div {
                                font-size: 12px;
                                color: #555B63;
                            }

                            .last{
                                text-align: right;
                            }
    
                            .change{
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*IPAD*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    
}