.markets-container {
    height: 100%;
    width: auto;
    margin: 0 auto;
    box-sizing: border-box;
    font-family: "Open sans";
    overflow-x: hidden;
    background-color: #F7F7F7;
    // position: absolute;
    // width: 100%;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(173, 173, 173);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #9c9ea0; 
    }
    .loader-no-border{
        border: none;
        border-radius: 5px;
    }

    .error-container {
        height: 100%;
        width: 100%;
        display: flex;
        background-color: #f7f7f7;
        align-items: center;
        justify-content: center;
        color: #7d7d7d;
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        border-radius: 5px;
        position:fixed;
        padding:0;
        margin:0;
        top:0;
        left:0;
        .error-info {
            font-size: 12px;
        }
    }

    .user-type-indicator{
        float: right;
        width: 100%;
        letter-spacing: normal;
        margin: 20px 25px;
        display: none;
    }
}

/*MOBILE*/
@media only screen and (max-device-width : 767px) {
    .error-container {
        font-size: 18px;
        border-radius: 0px;
        position: relative;
        height: 40%;
        background-color: #FFFFFF;
    }
}

/*IPAD*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    
}