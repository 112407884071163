.market-today {
  color: #ffffff;
  font-family: "Open Sans";
  margin-top: 75px;
  .head-line-container {
    margin: 10px 25px;
    .head-line-text {
      font-size: 28px;
      color: #1f1f1f;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
  .container {
    background-color: #081f31;
    padding: 25px;
    .spark-chart {
      height: 300px;
      max-width: 450px;
    }
    .market-new-container {
      .today-date {
        font-size: 33px;
        letter-spacing: -1px;
        line-height: 38px;
        font-weight: 300;
        font-family: "Open Sans";
        margin-bottom: 20px;
      }
      .news-middle-container {
        display: flex;
        flex-flow: wrap;
        width: 90%;
        .news-image-container {
          display: flex;
          width: 100%;
          .small-news-img {
            margin-right: 15px;
            width: 100px;
          }
          .news-head-line {
            font-weight: 400;
            font-family: "Open Sans";
            font-size: 18px;
            color: #ffffff;
            letter-spacing: 0;
            width: 80%;
          }
        }
        .exchange-name-container {
          margin-top: 10px;
          font-size: 11px;
          color: #91b8d8;
          letter-spacing: 0;
          .exchange-name {
            font-weight: 600;
            margin-right: 10px;
          }
        }
        .bottom-news-disc {
          margin: 10px 0;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 20px;
        }
        .read-full-story {
          font-size: 13px;
          color: #91b8d8;
          text-align: right;
          float: right;
          font-weight: 600;
          font-family: "Open sans";
          width: 100%;
          cursor: pointer;
        }
        .back-arrow {
          display: inline-block;
          width: 5px;
          height: 5px;
          margin-left: 7px;
          border-bottom: 2px solid #91b8d8;
          border-left: 2px solid #91b8d8;
          -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }
    }
  }
}

/*IPAD*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .market-today {
    .container {
      padding: 15px;
      .market-new-container {
        .today-date {
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px;
        }
        .news-middle-container {
          .news-image-container {
            .news-head-line {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
/*MOBILE*/
@media only screen and (min-device-width:350px) and (max-device-width: 767px) {
  .market-today {
    .container {
      padding: 10px 10px 20px 10px;
      .market-new-container {
        .today-date {
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px;
          margin: 15px;
        }
        .news-middle-container {
          .news-image-container {
            .news-head-line {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
