.tbl-holdings{
    min-height: 100px;
    width: 100%;
    font-family: "Open Sans";

    .no-data{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #7d7d7d;
        text-align: center;
        font-size: 25px;
        min-height: 100px;
    }

    .header {
        font-size: 13px;
        color: #555B63;
        font-weight: 600;

        .symbol{
            height: auto !important;
        }
    }

    .header-1 {
        border-bottom: 1px solid #AAADB1;
        padding: 8px 0 5px 0;
        box-sizing: border-box;
    }

    .row > div {
        padding: 0px 3% 0 0;
        box-sizing: border-box;
    }

    .row {
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .col-header{
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none; 
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;          
        }

        .symbol{
            width: 30%;
            padding-right: 5px;
            cursor: pointer;
            height: 55px;
            padding-left: 4px;
            box-sizing: border-box;

            .name{
                font-size: 14px;
                color: #2472B2;
                font-weight: 600;
                line-height: 16px;
                cursor: pointer;
                display: inline-block;
                margin-top: 12px;
            }

            .company{
                font-size: 13px;
                color: #555B63;
                line-height: 16px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .ratioPE{
            width: 15%;
            text-align: right;
            position: relative;
            padding-right: 5%;
        }

        .eps{
            width: 15%;
            text-align: right;
            padding: 0;
        }

        .dividend{
            width: 15%;
            text-align: right;
            padding: 0;
        }
        
        .dividend-yield{
            width: 15%;
            text-align: right;
            padding: 0;
        }

        .action{
            width: 10%;
            padding: 0px 10px 0 0;
            text-align: right;

            .del-icon{
               display: none;
            }
        }
        
        .bold {
            font-weight: 600;
            font-size: 13px;
            color: #1F1F1F;
        }
    }

    .data-row{
        padding: 0;
        border-bottom: 1px solid #E6E7E8;
        font-size: 14px;
        color: #1F1F1F;
        &:hover,&:focus-within {
            background: rgba(227,237,245,0.50);

            .del-icon{
                display: inline-block;
                cursor: pointer;
             }
        }
    }
}

/*IPAD*/
@media only screen and (max-width : 1024px) {
    .tbl-holdings{
        .row {
            .change{
                padding-right: 2%;
            }
        }
    }
}

/*IPAD*/
@media only screen and (max-width : 1000px) {
    .tbl-holdings{
        .row {
            .action{
                .del-icon{
                   display: inline-block;
                }
            }
        }
    }
}


/*MOBILE*/
@media only screen and (max-width : 768px) {
}
