.notification-box {
  position: relative;
  display: none;
  z-index: 1000;

  .notification {
    display: flex;
    align-items: center;
    position: absolute;
    left: 25%;
    width: 47%;
    padding: 1%;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid rgb(115, 115, 116);
    background: #FFF;
    box-shadow: 5px 5px 5px #aaaaaa;
  }

  .message {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #0e0e0e;
    margin-left: 2%;
    word-break: break-word;
  }

  .animateOpen {
    -webkit-animation-name: moveOpen;
    -webkit-animation-duration: 2s; 
    -webkit-animation-iteration-count: initial;
    -webkit-animation-fill-mode: forwards;

    animation-name:moveOpenIE;
    animation-duration: 2s; 
    animation-iteration-count: initial;
    animation-fill-mode: forwards;
  }

  /* Safari and Chrome */
  @-webkit-keyframes moveOpen {
    from {
      -webkit-transform: translate(0, -100px);
    }

    10% {
      -webkit-transform: translate(0, 20px);
    }

    12% {
      -webkit-transform: translate(0, 22px);
    }

    16% {
      -webkit-transform: translate(0, 20px);
    }

    80% {
      -webkit-transform: translate(0, 20px);
    }

    85% {
      -webkit-transform: translate(0, 25px);
    }

    to {
      -webkit-transform: translate(0, -100px);
    }
  }

  /* IE */
  @keyframes moveOpenIE {
    from {
      transform: translate(0, -100px);
    }

    10% {
      transform: translate(0, 20px);
    }

    12% {
      transform: translate(0, 22px);
    }

    16% {
      transform: translate(0, 20px);
    }

    80% {
      transform: translate(0, 20px);
    }

    85% {
      transform: translate(0, 25px);
    }

    to {
      transform: translate(0, -100px);
    }
  }
}

.notification-box-show {
  display: block;
}

@media only screen and (max-width : 767px) {
  .notification-box {
    .notification {
      left: 5%;
      width: 90%;
    }
  }
}