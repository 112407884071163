.news-article-parent-container {
    .markets-news-article-container{
        padding-top: 120px;
        position: static;
        width: 100%;
        height: calc(100% - 120px);
        left: 0;
        border-radius: 0px;        
        min-height: 60vh;
        div[data-news-article-container="true"] {
          height: auto;
        }
    }
    .header-container{
      padding-left: 25px;
      position: fixed;
      top: 70px;
      width: 100%;
      z-index: 99;
      margin: 0;
      background-color: #FFF;
      height: 50px;
      align-items: center;
      display: flex;
      box-shadow: 0 2px 4px 0 rgba(31,31,31,0.20);

        .back-btn{
            font-size: 16px;
            color: #2472B2;
            font-weight: 600;
            display: inline-block;
            background: transparent;
            border: none;
            outline: none;
            padding: 0;
            cursor: pointer;
            position: relative;
            z-index: 9999;
            font-family: "Open sans";
            
            .icon-back {
              display: inline-block;
              width: 8px;
              height: 8px;
              margin-right: 5px;
              border-top: 2px solid #2472B2;
              border-right: 2px solid #2472B2;
              transform: rotate(-135deg);
            }
        }
    }
    .applypositionAbs{
      position: absolute;
    }
}

/*IPAD*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  
}

/*MOBILE*/
@media only screen and (max-width : 767px) {
  .news-article-parent-container {
    .markets-news-article-container{
        padding-top: 50px;
        height: calc(100% - 50px);
        overflow-y: auto;
    }
    .header-container{
      height: 50px;
      background: #2472B2;
      top: 0;
      z-index: 9999;
      .back-btn {
        color: #FFFFFF;
        .icon-back {
          border-top: 2px solid #FFFFFF;
          border-right: 2px solid #FFFFFF;
        }
      }
    }
  }
}