.watchlist-card-container {
  display: block;
  // position: absolute;
  padding: 15px;
  background: #F7F7F7;
  width: 100%;
  min-height: calc(100vh - 140px);
  box-sizing: border-box;
  overflow: auto;
  
  .watchlist-card {
    float: left;
    background: #FFFFFF;
    border: 1px solid #E6E7E8;
    border-radius: 5px;
    width: 32%;
    height: 260px;
    margin: 0.5%;
    box-sizing: border-box;

    &:hover {
      border: 1px solid transparent;
      box-shadow: 1px 8px 10px 1px #AAADB1;
      -ms-transform: scale(1.03); /* IE 9 */
      -webkit-transform: scale(1.03); /* Safari 3-8 */
      transform: scale(1.03); 
  }
  }

  .as-of-date-container{
    text-align: right;
    margin-bottom: 7px;
    width: 98%;
  }
}

@media screen and (min-width:1024px) and (max-width: 1280px) {
  .watchlist-card-container {
    .watchlist-card {
      height: 260px;
    }
  }
}

@media screen and (min-width:768px) and (max-width: 1023px) {
  .watchlist-card-container {
    .watchlist-card {
      height: 240px;
      width: 49%;
    }
  }
}

@media screen and (max-width: 767px) {
  .watchlist-card-container {
    height: 75%;
    padding: 5px;
    position: absolute;
    margin-top: 59px;

    .watchlist-card {
      width: 98%;
      margin: 1.5%;
      height: 240px;
      &:hover {
        border: 1px solid #E6E7E8;
        box-shadow: none;
        transform: none;
    }
    }

    .as-of-date-container{
      margin-bottom: 0;
    }
  }
}
