.watchlist-container {
  height: 100%;
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  font-family: "Open sans";

  .search-symbol-container {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 25px 0 25px;
    padding: 10px 0;
    box-sizing: border-box;
  }

  .tabs-container {
    background-color: inherit;
    font-family: inherit;

    section {
      width: 100%;
      height: 100%;
      font-family: inherit;

      .tab {
        font-family: inherit;
        background: #F7F7F7;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        // position: absolute;
        height: 100%;
        padding: 25px;

        .tab-content-container {
          background: #FFFFFF;
          border-radius: 4px;
          min-height: 100px;
          padding: 15px;
          box-sizing: border-box;
        }
        
        .as-of-date-container{
          text-align: right;
          margin-bottom: 10px;
          width: 100%;
          margin-top: -10px;
        }
      }
    }
  }

  .watchlist-holdings-container {
    background-color: inherit;

    div[data-error="true"] {
      background-color: #FFFFFF;
    }

    .delete-symbol-container {
      font-family: Open Sans;
      width: 100%;
      height: inherit;
      background: #FFFFFF;

      .delete-symbol-header {
        font-size: 16px;
        font-weight: 600;
        color: #003057;
        letter-spacing: 0;
        margin: 3% 0;
        word-break: break-word;
      }

      .delete-symbol-footer {
        text-align: right;

        button {
          background: #FFFFFF;
          box-shadow: 1px 2px 3px 0 rgba(85, 91, 99, 0.20);
          border-radius: 3px;
          font-size: 14px;
          font-weight: 400;
          height: 40px;
          padding: 0 25px;
          
        }

        button:nth-child(1) {
          border: 1px solid #E6E7E8;
          color: #555B63;
          margin-right: 3%;
        }

        button:nth-child(2) {
          color: #CB223C;
          border: 2px solid #CB223C;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin: 15px 0 0 0;
      height: 20px;

      .page-sizes {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0 0 0 0;
          font-size: 14px;


          li:first-child {
            color: #555B63;
            cursor: default !important;
          }

          li {
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;
            color: #0065AA;

            &.selected {
              color: #1F1F1F;
              font-weight: 600;
            }
          }
        }
      }

      .pagination {
        font-size: 14px;
        color: #AAADB1;
        position: relative;
        display: inherit;

        .lbl-records-count {
          margin-right: 20px;
        }

        .action-buttons {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: #555B63;
          display: inherit;
          margin-top: 1px;

          .current-page-no {
            color: #1F1F1F;
          }

          .icon {
            width: 20px;
            height: 22px;
            display: inline-block;
            position: relative;
            cursor: pointer;
          }

          .icon-left-disabled {
            right: 2px;
            background-size: 22px 22px;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAZCAYAAADe1WXtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADwSURBVEhL7ZTZDsIgFET9/w+1dK9p0W6AjFJTdmL1wcST8AL1ZDL3xpP4An9pOsMwiIwQ0badunGTLO37QZwz8jrzsqgXm6iUcy4TUk1I8uJx7yMoxQ8pvVrCJZASBKWU6gkzkot1XdWrH6+0l0PRhbLHeVavYZxScyg4qUKgSZ9DMRPmUhju0ESTMsZFthPiTFN6wg0raV03mhTTZoH1cWF1CnHTtLaYMfVFHOegIO66iyYuijJpnYBTCpzisjq2pwBi/HnsxWVVR6sISoGrYyTGvY+odMMUh1YtWfqoQnX8saQAots4Hu/0HX5FKsQdC7gX7Qy0vaQAAAAASUVORK5CYII=)
          }

          .icon-left-active {
            right: 2px;
            transform: rotate(180deg);
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAbCAYAAACX6BTbAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFeSURBVEhL7ZPNSgJRFMd7mMoKWgRuiuhReojMCCxQgqBdu55BAiFnYkprW0GQmBMtJCjyY7Q0U3SsjJNnuqZ3xvtFRBt/cDdzht+Z879nxuAPGcmH8j/y1lsHlvfOwL9hwHn2iTxVgynXrnIwvhJ3zsyaDslMkVTkYcozjzXwrWpUg1PTIlU5uJlf3lUcaa/BdFBXikh4oan7KkwG+hPgNFmrQap8hHLkpBuHu4HMBFJyRE/lwTfQACMS3YG0HMENGpzA2SKTvUVKciR68fAjx7MQTpCKFyW5VbNhdv2Qkm/up0nVi7S89GrDfPiYEm8fmPDR+SRveJGSF19asLSVpMS7xi28c8SIUF6utynxRPfsaDfcL+7BlZfrNixGEn1xIA6R2DWpimHKnxttmAsZ1BeriBGm3L1yGIUqTHm+2oSp4PcPg5cnk7Ebbua5ShOO0gXhVrDgyn/LSD4EgC88+jDt4UJw2AAAAABJRU5ErkJggg==)
          }

          .icon-right-disabled {
            left: 0px;
            transform: rotate(180deg);
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAZCAYAAADe1WXtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADwSURBVEhL7ZTZDsIgFET9/w+1dK9p0W6AjFJTdmL1wcST8AL1ZDL3xpP4An9pOsMwiIwQ0badunGTLO37QZwz8jrzsqgXm6iUcy4TUk1I8uJx7yMoxQ8pvVrCJZASBKWU6gkzkot1XdWrH6+0l0PRhbLHeVavYZxScyg4qUKgSZ9DMRPmUhju0ESTMsZFthPiTFN6wg0raV03mhTTZoH1cWF1CnHTtLaYMfVFHOegIO66iyYuijJpnYBTCpzisjq2pwBi/HnsxWVVR6sISoGrYyTGvY+odMMUh1YtWfqoQnX8saQAots4Hu/0HX5FKsQdC7gX7Qy0vaQAAAAASUVORK5CYII=)
          }

          .icon-right-active {
            left: 4px;
            background-size: 22px 22px;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAbCAYAAACX6BTbAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFeSURBVEhL7ZPNSgJRFMd7mMoKWgRuiuhReojMCCxQgqBdu55BAiFnYkprW0GQmBMtJCjyY7Q0U3SsjJNnuqZ3xvtFRBt/cDdzht+Z879nxuAPGcmH8j/y1lsHlvfOwL9hwHn2iTxVgynXrnIwvhJ3zsyaDslMkVTkYcozjzXwrWpUg1PTIlU5uJlf3lUcaa/BdFBXikh4oan7KkwG+hPgNFmrQap8hHLkpBuHu4HMBFJyRE/lwTfQACMS3YG0HMENGpzA2SKTvUVKciR68fAjx7MQTpCKFyW5VbNhdv2Qkm/up0nVi7S89GrDfPiYEm8fmPDR+SRveJGSF19asLSVpMS7xi28c8SIUF6utynxRPfsaDfcL+7BlZfrNixGEn1xIA6R2DWpimHKnxttmAsZ1BeriBGm3L1yGIUqTHm+2oSp4PcPg5cnk7Ebbua5ShOO0gXhVrDgyn/LSD4EgC88+jDt4UJw2AAAAABJRU5ErkJggg==)
          }
        }
      }
    }
  }
}

/*MOBILE*/
@media only screen and (max-width : 767px) {
  .watchlist-container {
    background-color: #F7F7F7;
    position: relative;
    top: 0;
    left: 0;
    font-family: "Open sans";
    overflow-x: hidden;
    min-height: 100vh;

    .back-to-watchlist-btn-container {
      background: #2472B2;
      height: 50px;
      width: 100%;
      padding: 14px 10px;
      box-sizing: border-box;
      position: fixed;
      top: 0;
      z-index: 99;

      button {
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 600;
        background: transparent;
        border: none;
        outline: none;

        .icon-back {
          color: #FFFFFF;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-top: 2px solid #FFFFFF;
          border-right: 2px solid #FFFFFF;
          transform: rotate(-135deg);
        }
      }
    }

    .watchlist-detail-container {
      margin-top: 50px;
      .name-and-search-container {
        padding: 15px 0 20px 20px;
        box-sizing: border-box;
        background-color: #FFF;

        .name {
          display: inline-block;
          font-weight: 600;
          font-size: 20px;
          color: #003057;
          width: 80%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          position: absolute;
        }

        .search-symbol-container {
          display: inline-block;
          margin: 0 25px 0 25px;
          padding: 10px 0;
          box-sizing: border-box;
        }
      }

      .tabs-container {
        background-color: inherit;
        font-family: inherit;

        ol {
          display: block;
          background-color: #FFF;
          border: none;
          margin: 0;
          padding: 0 0 0 5px;
          width: 100%;

            section {
                width: 100%;
                height: 100%;
                font-family: inherit;
                margin-top: -10px;

                .tab {
                    font-family: inherit;
                    background: #F7F7F7;
                    border-radius: 4px;
                    width: 100%;
                    box-sizing: border-box;
                    height: 100%;            
                    padding: 10px;
    
                    .tab-content-container {
                        background: #FFFFFF;
                        border-radius: 4px;
                        min-height: 100px;
                        padding: 10px;
                        box-sizing: border-box;    
                        border: 1px solid #E6E7E8;                
                    }
                }
              }
          div {
            font-size: 18px;
            color: #AAADB1;
            text-align: center;
            font-weight: normal;
            margin: 0px 5px 8px 10px;

            &[data-is-active-tab="true"] {
              color: #2472B2;
            }
          }
        }

        select {
          display: none;
        }

        section {
          width: 100%;
          height: 100%;
          font-family: inherit;
          margin-top: -10px;

          .tab {
            font-family: inherit;
            background: #F7F7F7;
            border-radius: 4px;
            width: 100%;
            box-sizing: border-box;
            height: 100%;
            padding: 10px;

            .tab-content-container {
              background: #FFFFFF;
              border-radius: 4px;
              min-height: 100px;
              padding: 15px;
              box-sizing: border-box;
              border: 1px solid #E6E7E8;
            }

            .as-of-date-container{
              margin-top: 0;
            }
          }
        }
      }
    }

    .footer-container {
      padding: 0 12px;

      button {
        padding: 10px 20px;
        width: 100%;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width : 350px) {
  .watchlist-container {
    .tabs-container {
      ol {
        div {
          font-size: 14px;
        }
      }
    }
  }
}