.container {
    height: 100%;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    background-color: #f7f7f7;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #7d7d7d;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
        
    div {
        top: 10%;
        position: absolute;
    }

    a {
        color: #7d7d7d;
        text-decoration: underline;
    }
}
