.indices-container {
  font-family: Open Sans;
  width: 100%;
  cursor: pointer;
  .exchange-name {
    width: 100%;
    font-size: 1.5vw;
    color: #FFFFFF;
    span:first-child {
      float: left;
      font-weight: 600;
    }
    span:nth-child(2) {
      float: right;
    }
  }
  .today-quote-price {
    width: 100%;
    float: right;
    font-size: 1.2vw;
    font-weight: 400;
    letter-spacing: 0;
    text-align: right;
    line-height: 16px;
  }
}

/*IPAD*/

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .indices-container {
    .today-quote-price {
      font-size: 1.4vw;
    }
  }
}

/*MOBILE*/
@media only screen and (max-width: 767px) {
  .indices-container {
    .exchange-name {
      font-size: 16px;
    }
    .today-quote-price {
      font-size: 14px;
    }
  }
}



