.watchlist-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 18px;
  color: #2472B2;
  letter-spacing: 0;
  border-bottom: 1px solid #E6E7E8;
  padding: 0 10px;
  height: 50px;
  width: 100%;
  box-sizing: border-box;

  .watchlist-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }

  .watchlist-action-icons {
    display: none;
    right: 0;

    .watchlist-edit-icon {
      margin-right: 5px;
      cursor: pointer;
    }

    .watchlist-delete-icon {
      width: 16px;
      height: 22px;
      background-position-x: 0;
      background-position-y: 0;
      display: inline-block;
      cursor: pointer;
    }
  }

  .arrow-forward {
    display: none;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-top: 2px solid #2472B2;
    border-right: 2px solid #2472B2;
    transform: rotate(50deg);
  }
}

.watchlist-header:hover,.watchlist-header:focus-within {
  background: rgba(227, 237, 245, 0.50);
  
  .watchlist-action-icons {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .watchlist-header {
    .arrow-forward {
      display: block;
    }
  }
  .watchlist-header:hover {
    background: none;

    .watchlist-action-icons {
      display: none;
    }
  }
}