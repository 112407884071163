.news-list-item-container{
    display: block;
    border-bottom: 1px solid #E6E7E8;
    padding: 15px 0;
    white-space: normal;
    
    .hotstory-container{
        margin-bottom: 10px;
        span{
            background: #527316;
            font-size: 13px;
            color: #FFFFFF;
            font-weight: 600;
            height: 80px;
            width: 25px;
            padding: 3px 8px;
            border-radius: 3px;
        }
    }

    .news-container{
        display: flex;
    }

    .img-container{
        display: inline-block;
        height: 90px;
        width: 130px;
        margin-right: 15px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 5px;
    }

    .news-info{
        display: inline-block;
        width: 100%;

        .publisher-info {
            .provider {
                font-weight: 600;
                font-size: 13px;
                color: #555B63;
                margin-right: 10px;
            }

            .date {
                font-size: 13px;
                color: #555B63;
            }
        }

        h3 {
            font-size: 16px;
            color: #2472B2;
            cursor: pointer;
            font-weight: 600;
            margin: 2px 0px 10px 0px;
        }

        .teaser{
            font-size: 14px;
            color: #1F1F1F;
        }
    }
}

/*MOBILE*/
@media only screen and (max-width : 768px) {
    .news-list-item-container{
        width: 100%;
        padding: 0;
        padding: 5px 0px;

        .hotstory-container {
            margin-bottom: 5px;
            margin-top: 5px;
            span{
                font-size: 12px;
            }
        }

        .img-container{
            height: 60px;
            width: 90px;
            margin-right: 8px;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .news-info{
            padding: 0px;
            h3 {
                font-size: 13px;
                margin: 0;
            }
            .teaser{
                display: none;
            }
        }
    }
}