@media only screen and (min-width: 768px) {
    .news-card-container {
        min-height: 245px;
        display: inline-block;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #E6E7E8;
        border-radius: 5px;
        color: #E6E7E8;
        margin-bottom: 10px;
        font-family: "Open sans";
        // overflow: hidden;
        cursor: pointer;
        
        .news-card {
            height: 100%;
            position: relative;
            padding: 5px 5px 5px 10px;
            box-sizing: border-box;
            display: flex;
            flex-flow: column;

            .title{
                font-size: 1.3vw;
                color: #2472b2;
                font-weight: 600;
                box-sizing: border-box;
                position: relative;
                margin-top: 10px;
                cursor: pointer;
            }

            .publisher-info {
                .provider {
                    font-size: 0.9vw;               
                    font-weight: 600;
                    color: #555B63;
                    margin-right: 10px;
                }

                .date {
                    font-size: 0.9vw;
                    color: #555B63;
                }
            }

            .teaser{
                font-weight: 600;
                font-size: 1.1vw;
                color: #555B63;
                height: 100%;
                display: flex;
                align-items: flex-end;
                margin-top: 10%;
                width: 95%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }
        }

        .image-news-card {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;
            height: 100%;
            width: 100%;
            padding: 0px;
            border-radius: 5px;
            
            .overlay{
                opacity: 0.8;
                background: #081F31;
                height: auto;
                position: absolute;
                bottom: 0px;
                min-height: 30%;
                width: 100%;
                box-sizing: border-box;
                padding: 14px 15px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;

                .title{
                    color: #FFFFFF;
                    padding: 0px;
                    font-size: 1.2vw;
                    margin-top: 0px;
                }

                .publisher-info {
                    margin-top: 5px;
                    padding: 0px;

                    .provider {
                        color: #E6E7E8;
                    }
            
                    .date {
                        font-size: 0.9vw;
                        color: #E6E7E8;
                    }
                }
                
            }
        }
    }

    div[data-card-no="1"] {    
        width: 49.5%;
        margin-right: 1%;
        // background-color: #d4dce3;
    }

    div[data-card-no="2"] {
        width: 49.5%;
    }

    div[data-card-no= "3"] {
        width: 49.5%;
        margin-right: 1%;
    }

    div[data-card-no= "4"] {
        width: 49.5%;
        // background-color: #d3e3f0;
    }
}

/**DESKTOP*/
@media only screen and (min-width : 1025px) {
    
}

/*IPAD*/
@media only screen and (min-width : 768px) and (max-width : 1024px) {
    .news-card-container {
        min-height: 197px;
    }
}

/*MOBILE*/
@media only screen and (max-width: 767px) {
    .news-card-container {
        height: 230px;
        width: 48% !important;
        display: inline-block;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #E6E7E8;
        border-radius: 5px;
        color: #E6E7E8;
        font-family: "Open sans";
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0 2px 4px 0 rgba(31,31,31,0.20);
        margin-bottom: 12px !important;

        .news-card {
            height: 100%;
            position: relative;
            padding: 5px 5px 5px 10px;
            box-sizing: border-box;
    
            .top-img{
                height: 40%;
            }
    
            .middle-title{
                height: 40%;
                font-weight: 600;
                font-size: 14px;
                color: #2472b2;
                letter-spacing: 0;
                line-height: 17px;
            }
    
            .bottom-publisher{
                font-size: 11px;
                color: #555B63;
                letter-spacing: 0;
                height: auto;
                position: absolute;
                bottom: 0px;
                width: 100%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 5px 5px 5px 8px;
                left: 0;
    
                .publisher-info {
                    .provider {
                        font-weight: normal;
                    }
                }
            }
        }
    }
    
    div[data-card-no="1"], div[data-card-no="4"], div[data-card-no="5"] {    
        // background-color: #d3e3f0;
    }

        
    div[data-card-no="1"], div[data-card-no="2"], div[data-card-no="3"], div[data-card-no="4"] {    
        margin: 0;
    }
}