.loader{
    background-color: white;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E6E7E8;
    height: 100%;
    .rollingloader {
        height: 50px;
        width: 50px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTBweCIgIGhlaWdodD0iNTBweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLXJvbGxpbmciIHN0eWxlPSJiYWNrZ3JvdW5kOiBub25lOyI+PGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgZmlsbD0ibm9uZSIgbmctYXR0ci1zdHJva2U9Int7Y29uZmlnLmNvbG9yfX0iIG5nLWF0dHItc3Ryb2tlLXdpZHRoPSJ7e2NvbmZpZy53aWR0aH19IiBuZy1hdHRyLXI9Int7Y29uZmlnLnJhZGl1c319IiBuZy1hdHRyLXN0cm9rZS1kYXNoYXJyYXk9Int7Y29uZmlnLmRhc2hhcnJheX19IiBzdHJva2U9IiMxMjgwZmYiIHN0cm9rZS13aWR0aD0iMTAiIHI9IjM1IiBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHRyYW5zZm9ybT0icm90YXRlKDM2MCAtOC4xMDg3OGUtOCAtOC4xMDg3OGUtOCkiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjFzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+PC9jaXJjbGU+PC9zdmc+);
    }
    .rollingloader-pos{
        position: absolute;
        @media screen and (min-width:1024px) and (max-width: 1280px) {
            top: 45%;
            left: 46%;
        }
        @media screen and (min-width:350px) and (max-width: 767px) {
            left: 46%;
            top: 45%;
        }
        @media screen and (min-width:768px) and (max-width: 1023px) {
            left: 46%;
            top: 40%;
        }
    }
}
.loader-no-border{
    border: none;
    border-radius: 5px;
}
.sr-only {
	position: absolute; 
	overflow: hidden; 
	clip: rect(0 0 0 0); 
	height: 1px;
	width: 1px; 
	margin: -1px;
	padding: 0;
	border: 0; 
}