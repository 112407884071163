.markets-news-parent-container{
    width: 100%;
    box-sizing: border-box;
    margin-top: 25px;
    font-family: "Open sans";
    overflow: hidden;

   .no-news{
        width: 100%;
        background-color: #f7f7f7;
        color: #7d7d7d;
        text-align: center;
        font-size: 25px;
        border-radius: 5px;
        margin-top: 30px;
    }

    .tabs-container{
        font-family: inherit;
        .tab {
            font-family: inherit;
            background-color: #F7F7F7;
            padding: 20px 25px 20px 25px;
            box-sizing: border-box;

            .news-container{
                overflow: inherit;

                .top-5-news-container {
                    display: flex;
                    flex-flow: wrap;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    
                    .news-card-container {
                        background-color: #fff;
                        transition: transform .2s;
                        border-radius: 5px;
                        
                        &:hover {
                            border: 1px solid transparent;
                            box-shadow: 1px 8px 10px 1px #AAADB1;
                            -ms-transform: scale(1.03); /* IE 9 */
                            -webkit-transform: scale(1.03); /* Safari 3-8 */
                            transform: scale(1.03); 
                        }

                        &[data-card-no="1"],
                        &[data-card-no="2"],
                        &[data-card-no="3"],
                        &[data-card-no="4"],
                        &[data-card-no="5"],
                        &[data-card-no="6"] {
                            margin-right: 0;
                            width: 32.7%;
                        }
                    }
                }

                .news-list-container{
                    width: 100%;
                    position: relative;
                    box-sizing: border-box;
                    margin-top: 20px;
                }
            }
        }
    }
}

/*MOBILE*/
@media only screen and (max-width : 768px) {
    .markets-news-parent-container {
        .tabs-container{
            font-family: inherit;

            > div > select {
                margin-bottom: 25px !important;
            }

            .tab {
                padding:10px !important;

                .news-container{
                    margin: 0;
                    overflow: inherit;
                    padding: 0 10px 10px 10px;
                    background: #FFFFFF;
                    border: 1px solid #E6E7E8;
                    border-radius: 5px;
                    
                    .show-more-btn-container {
                        .show-more-btn {
                            background: transparent;
                            font-size: 14px;
                            color: #2472B2;
                            border-radius: 3px;
                            width: 97px;
                            height: 35px;
                            font-weight: 600;
                            cursor: pointer;
                            border: none;
                            padding: 0;
                            margin-top: 8px;

                            .icon{
                                display: inline-block;
                                width: 8px;
                                height: 8px;
                                margin-right: 5px;
                                border-top: 2px solid #2472B2;
                                border-right: 2px solid #2472B2;
                                position: relative;
                            }
                
                            .up{
                                top: 3px;
                                left: 6px;
                                transform: rotate(-45deg);
                            }
                
                            .down{
                                top: -2px;
                                left: 3px;     
                                transform: rotate(135deg);                       
                            }
                        }
                    }

                    .top-3-news-container {
                        background-color: inherit;
                    }

                    .news-list-container{
                        margin-top: 8px !important;
                        border: none;
                        padding: 0;
                        border-top: 1px solid #E6E7E8;
                        border-radius: 0;
                    }

                    .news-list-item-container{
                        padding: 10px 0px;
                        .publisher-info{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/*IPAD*/
@media only screen and (min-width : 768px) and (max-width : 1024px) {
    
}