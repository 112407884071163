.top-mover {
  min-width: 20%;
  max-width: 20%;
  margin-left: 1%;
  .top-mover-header {
    font-size: 15px;
    font-weight: 600;
    color: #555B63;
    letter-spacing: 0;
    margin: 12px 0;
  }
  .top-mover-details {
    background: #FFFFFF;
    border: 1px solid #E6E7E8;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;

    .company-details {
      margin-bottom: 15px;
      .company-name {
        font-size: 20px;
        font-weight: 600;
        color: #003057;
        letter-spacing: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }

      .symbol-exchange {
        font-size: 15px;
        color: #555B63;
        letter-spacing: 0;

        span:nth-child(1) {
          font-weight: 600;
        }
      }
    }

    .last-price {
      margin-bottom: 15px;
    }

    .last-price,
    .today-change {
      .label {
        font-size: 12px;
        font-weight: 600;
        color: #555B63;
      }

      .value {
        font-size: 16px;
        color: #1F1F1F;
        line-height: 20px;
      }
    }
  }
}