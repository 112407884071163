.heading{
    background-color: #FFFFFF;
}
.gainers-top-row{
    border-top: 1px solid #CCCCCC;
}
.losers-top-row{
    border-top: 1px dotted #CCCCCC;
}
.user-type-indicator {
    float: right;
    width: 100%;
    letter-spacing: normal;
}
.news-header{
    border-bottom: 1px solid #CCCCCC;
    @media screen and (min-width:320px) and (max-width:768px) {
        border-bottom: none;
    }
}
.news-header-stock-etf{
    border-bottom: 1px solid #CCCCCC;
}
.clickable-links{
    padding-top: 4px;
    display: inline
}
.inline{
    float: right;
}
.news-mobile{
    padding-top: 11px;
    padding-bottom: 11px;
    border-bottom: 1px solid #ccc;
}
.news-clickable-links{
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 14px;
}
.news-header-relative{
position: relative;
}
.gainers{
    padding-right: 15px;
    box-shadow: 1px 1px 5px 1px #d0cfce;
    background-color: #f9f9f9;
}
.divider{
    display: inline;
    border-right: 1px solid #CCCCCC;
    padding-left: 5px;
}
.shadow{
    box-shadow: 1px 1px 5px 1px #d0cfce;
}
.news-link{
    text-decoration: none;
    display: block;
    width: 25%;
    @media screen and (min-width:530px) and (max-width:768px) {
        width: 30%;
    }
    @media screen and (min-width:320px) and (max-width:529px) {
        width: 54%;
    }
    margin: auto;
}
.p-r-3{  
/* pure-hidden-lg */
@media screen and (min-width:1024px) and (max-width:1279px) {
    padding-right:30px;
}
/* pure-hidden-xl */
@media screen and (min-width:1280px) {
    padding-right:30px;
}
}
.p-r-4{  
        padding-right:40px;
}
.m-r-3 {  
    /* pure-hidden-lg */
    @media screen and (min-width:1024px) and (max-width:1279px) {
        margin-right:30px;
        border-bottom: 1px solid #ccc;
    }
    /* pure-hidden-xl */
    @media screen and (min-width:1280px) {
        margin-right:30px;
        border-bottom: 1px solid #ccc;
    }
}
.m-noborder{
      /* pure-hidden-lg */
      @media screen and (min-width:1024px) and (max-width:1279px) {
        margin-right:30px;

    }
    /* pure-hidden-xl */
    @media screen and (min-width:1280px) {
        margin-right:30px;

    }
}
.p-b-3 {
    padding-bottom :45px;
}
.latest-news {
    padding-top: 26px;
}
.pagination {
    font-family: "Open sans";
    font-size: 12px;
    text-align: center;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-bottom: 45px;
    padding-top: 20px;
    .pagination-arrow-left {
        padding-left: 15px;
    }
    
    .pagination-arrow-right {
        padding-right: 15px;
    }
    .input-box {
        width: 35px;
        height: 25px;
        border-radius: 6px;
        text-align: center;
        box-shadow: none;
        display: inline-block;
    }
}
.disclaimer{
    float: right;
    padding-top: 4px;
}
.p-t-2{
    padding-top: 20px;
}
.p-b-2{
    padding-bottom: 30px;
}
.m-t{
    margin-top: 100px;
    @media screen and (min-width:768px) and (max-width: 1023px) {
        margin-top:135px;
    }
    @media screen and (min-width:1024px) and (max-width: 1280px) {
        margin-top:115px;
    }
    @media screen and (max-width: 767px) {
        margin-top:65px;
    }
}
.m-t-p{
    margin-top: 120px;
    @media screen and (min-width:768px) and (max-width: 1023px) {
        margin-top:155px;
    }
    @media screen and (min-width:1024px) and (max-width: 1280px) {
        margin-top:145px;
    }
    @media screen and (max-width: 767px) {
        margin-top:85px;
    }
}

.m-t-news{
    margin-top: 120px;
    @media screen and (min-width:768px) and (max-width: 1023px) {
        margin-top:0px;
    }
    @media screen and (min-width:1024px) and (max-width: 1280px) {
        margin-top:145px;
    }
    @media screen and (max-width: 767px) {
        margin-top:0px;
    }
}

.no-data{
  height: 40%;
  position: absolute;
  width: 100%;
  display: flex;
  background-color: #f7f7f7;
  align-items: center;
  justify-content: center;
  color: #7d7d7d;
  text-align: center;
  font-size: 25px;
  border-radius: 5px;
  letter-spacing: normal;
}

.no-news{
    text-align: center;
    color: #333;
    font-size: 16px;
    font-family: "Open sans";
    font-weight: 600;
    padding-bottom: 30px;
}
.newsListContainer{
    background-color: white;
    border-radius: 5px;
    margin: 10px 10px !important;
}