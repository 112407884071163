/**
* Colors
*/
$color-stifel-intyce-gray-border: #DBDBDB;
$color-stifel-intyce-black: #000000;
$color-stifel-intyce-blue-active-dark: #086B94;
$color-stifel-intyce-blue-active-light: #0065aa;
$color-stifel-intyce-blue-active-medium: #007FAC;
$color-stifel-intyce-blue-core: #12395B;
$color-stifel-intyce-blue-core-dark: #0F1E31;
$color-stifel-intyce-blue-core-medium: #0D2F4C;
$color-stifel-intyce-blue-navy: #003057;
$color-stifel-intyce-blue-dark: #12627B;
$color-stifel-intyce-blue-light: #6999AF;
$color-stifel-intyce-blue-medium: #57879D;
$color-stifel-intyce-brown-dark: #533F27;
$color-stifel-intyce-brown-light: #BFA67B;
$color-stifel-intyce-brown-medium: #996C40;
$color-stifel-intyce-gray-cool-dark: #424951;
$color-stifel-intyce-gray-cool-light: #F2F3F3;
$color-stifel-intyce-gray-cool-medium: #333333;
$color-stifel-intyce-gray-dark: #496A7A;
$color-stifel-intyce-gray-light: #999999;
$color-stifel-intyce-gray-warm-dark: #D0CFCE;
$color-stifel-intyce-gray-dotted: #CCCCCC;
$color-stifel-intyce-gray-warm-light: #EDECEA;
$color-stifel-intyce-gray-warm-medium: #E0DEDC;
$color-stifel-intyce-gray-warm: #666666;
$color-stifel-intyce-green-active: #527316;
$color-stifel-intyce-green-active-dark: #435F21;
$color-stifel-intyce-green-dark: #325237;
$color-stifel-intyce-green-light: #87864A;
$color-stifel-intyce-red: #AA0000;
$color-stifel-intyce-red-dark: #960C11;
$color-stifel-intyce-yellow: #FAC705;
$color-stifel-intyce-yellow-dark: #E8AB06;
$color-stifel-intyce-yellow-highlight: #FEF7DA;
$color-stifel-intyce-white: #FFFFFF;
$color-stifel-intyce-white-light:#f9f9f9;
$color-stifel-intyce-search-icon:#3580BA;

/**
* Font sizes
*/
$font-size-9:  9px;
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-30: 30px;
$font-size-34: 34px;
$font-size-40: 40px;

/**
* Font weights
*/
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;

/**
* Spacing
*/
$spacing-0: 0px;
$spacing-5: 5px;
$spacing-8: 8px;
$spacing-10: 10px;
$spacing-15: 15px;
$spacing-20: 20px;
$spacing-22: 22px;
$spacing-30: 30px;
$spacing-34: 34px;
$spacing-40: 40px;
$spacing-45: 45px;
$spacing-60: 60px;
$spacing-80: 80px;
$spacing-90: 90px;
$spacing-120: 120px;

$spacer-map: (
	0: $spacing-0,
	1: $spacing-5,
	2: $spacing-8,
	3: $spacing-10,
	4: $spacing-15,
	5: $spacing-20,
	6: $spacing-22,
	7: $spacing-30,
	8: $spacing-34,
	9: $spacing-40,
	10: $spacing-45,
	11: $spacing-60,
	12: $spacing-80,
	13: $spacing-90,
	14: $spacing-120
);
