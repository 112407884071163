.add-to-watchlist-container {
  margin: 0;
  margin-left: 12px;
  display: inline-block;
  list-style-type: none;
  background-color: #FFF;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 15px;
  top: -10px;

  .no-wrap{
    white-space: nowrap !important;
  }

  .btn-add-to-watchlist {
    cursor: pointer;

    .btn-add-icon {
      width: 18px;
      height: 20px;
      top: 3px;
      position: relative;
      background-position: -16px -22px;
      display: inline-block;
      right: 3px;
      cursor: pointer;
    }

    .btn-add-icon-text {
      top: -2px;
      position: relative;
    }

    list-style-type: none;
    background-color: #FFF;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    -webkit-box-shadow: 0px 2px 6px 0px #dbdbdb;
    box-shadow: 0px 2px 6px 0px #dbdbdb;
    color: #2472B2;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    outline: none;
    margin: 0;

    li:not(:first-child) {
      display: none;
    }

    &.open {
      padding: 0px;
      border-radius: 0;
      width: 200px;
      z-index: 999;
      position: absolute;

      li {
        padding: 8px 10px 8px 10px;
        box-sizing: border-box;
        border-top: 1px solid #f7f7f7;
        cursor: default;

        &:not(:first-child) {
          display: block;
        }

        &:nth-child(2) {
          border-bottom: 1px solid #dbdbdb;
        }

        span.watchlist-name {
          width: 90%;
          display: inline-block;
          cursor: default;
          color: #555B63;
          word-break: break-word;
        }

        &:first-child {
          display: none;
        }

        &:last-child {
          .add-icon {
            font-size: 20px;
            font-weight: 800;
            margin-right: 5px;
          }
        }

        &.disabled {
          span {
            color: #AAADB1;
          }
        }

        &.create-new-watchlist {
          span {
            cursor: pointer;
          }
        }
      }
    }

    .loader {
      height: 300px;

      div {
        left: 38%;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .add-to-watchlist-container {
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  .add-to-watchlist-container {
    top: 0px;

    .btn-add-to-watchlist {
      padding: 4px 10px 5px 10px;
    }

    &.open {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #FFF;
      z-index: 99;
      overflow-y: auto;
    }

    .watchlists-page-container {
      .content-container {
        padding: 0 20px;

        h3 {
          font-weight: 600;
          font-size: 20px;
          color: #003057;
        }

        ul.tab-buttons {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          list-style-type: none;
          border: 1px solid #AAADB1;
          border-radius: 3px;

          li {
            padding: 10px 0;
            font-size: 14px;
            color: #2472B2;
            font-weight: 600;
            width: 50%;
            text-align: center;

            &.active {
              background: #2472B2;
              color: #FFFFFF;
            }
          }
        }

        .watchlists-container {
          .no-watchlist {
            font-weight: 600;
            font-size: 16px;
            color: #1F1F1F;
            text-align: center;
          }

          .message-container {
            text-align: center;
            color: green;
            margin-bottom: 5px;
            font-weight: 600;
            margin-top: -15px;
          }

          margin-top: 25px;
          margin-bottom: 80px;

          .row {
            padding: 10px 0;
            border-top: 1px solid #E6E7E8;
            display: flex;
            justify-content: center;
            align-items: center;

            .watchlist-info {
              width: 90%;
              display: inline-block;
              word-break: break-all;

              .wacthlist-name {
                font-weight: 600;
                font-size: 16px;
                color: #1F1F1F;

                span {
                  font-weight: normal;
                  font-size: 16px;
                  color: #555B63;
                  margin-left: 5px;
                }
              }

              .wacthlist-holdings-count {
                font-size: 14px;
                color: #555B63;
              }
            }

            .checkbox-container {
              width: 10%;
              text-align: right;
              display: inline-block;

              /* The container */
              .container {
                height: 20px;
                width: 20px;
                display: block;
                position: relative;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
              }

              /* Hide the browser's default checkbox */
              .container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
              }

              /* Create a custom checkbox */
              .checkmark {
                position: absolute;
                top: -6px;
                left: 5px;
                height: 20px;
                width: 20px;
                border: 2px solid #ccc;
              }

              /* When the checkbox is checked, add a blue background */
              .container input:checked~.checkmark {
                background-color: #2472B2;
              }

              .container input:disabled~.checkmark {
                background-color: #bdbdbd;
              }

              /* Create the checkmark/indicator (hidden when not checked) */
              .checkmark:after {
                content: "";
                position: absolute;
                display: none;
              }

              /* Show the checkmark when checked */
              .container input:checked~.checkmark:after {
                display: block;
              }

              /* Style the checkmark/indicator */
              .container .checkmark:after {
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }

            button {
              border-radius: 5px;
              border: 1px solid #979797;
              font-weight: 600;
              font-size: 14px;
              color: #555B63;
              text-align: center;
              padding: 12px 25px;
              margin: 10px 0 0 20px;
              background: #FFF;
              font-size: 14px;

              &.blue {
                color: #FFFFFF;
                background: #003057;
              }
            }

            &.footer {
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}