.indice-overview-container{
    position: relative;
    min-height: 320px;

    .chart-view {
      min-height: 250px;
    }
    
    .indice-loader{
        top: 38%;
    }
 
    .bottom-container{
        margin-top: 5px;

        .date{
            font-size: 12px;
            color: #555B63;
            font-family: "Open Sans";
        }
    }
}

 /*MOBILE*/
 @media only screen and (max-width : 768px) {
    .indice-overview-container {
        padding: 15px 20px;
    }
}