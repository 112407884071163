.header-container-top {
  position: fixed;
  z-index: 999;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  top: 0;
  .header-container {
    font-family: Open Sans;
    background: #ffffff;
    padding: 0 25px;
    height: 68px;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    .indice-quotes {
      width: 80%;
      display: flex;
      flex-wrap: nowrap;
    }
    .indice-quotes > div {
      margin-right: 7%;
    }
    .indice-quotes div {
      color: #1f1f1f !important;
    }
    .search-symbol {
      width: 20%;
    }
    form {
      box-shadow: none;
      float: right;
      width: 100%;
      label {
        border: 1px solid #aaadb1;
        color: #6C717D;
        font-style: normal;
        border-radius: 3px;
        div > span {
          height: 15.5px !important;
          width: 15.5px !important;
          z-index: 0;
        }
        input {
          padding-right: 0;
          padding-left: 3%;
          font-size: 1vw;
          padding-top: 7px;
        }
        input::placeholder {
          color: #6C717D;
          font-style: normal;
        }
      }
    }
  }
}

/*IPAD*/

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header-container-top {
    .header-container {
      form {
        label {
          input {
            height: 30px;
            padding-right: 20px;
            padding-left: 5px;
            font-size: 1.1vw;
          }
          div {
            height: 30px !important;
            width: 30px !important;
          }
          div > span {
            left: 65% !important;
          }
        }
      }
    }
  }
}

/*MOBILE*/
@media only screen and (max-width: 767px) {
  .header-container-top {
    .header-container {
      padding: 0 10px;
      .search-symbol {
        width: 100% !important;
      }
      form {
        label {
          input {
            font-size: 16px;
          }
          div > span {
            height: 30px;
            width: 30px;
            left: 50%;
          }
        }
      }
    }
  }
}
