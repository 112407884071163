.spark-chart-container {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  width: 100%;
  .content-container {
    display: flex;
    background-color: #081F31;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 15px;
    cursor: pointer;
    border-bottom: 2px solid #013c6b;
  
    &:hover {
      border-bottom: 2px solid #013c6b;
      box-shadow: 1px 1px 10px 1px #013c6b;
      -ms-transform: scale(1.03, 1.0); /* IE 9 */
      -webkit-transform: scale(1.03, 1.0); /* Safari 3-8 */
      transform: scale(1.03, 1.0); 
      z-index: 100;
    }
    .indices-container {
      width: 45%;
      margin-right: 5%;
      box-sizing: border-box;
    }
    .spark-chart {
      width: 50%;
      img {
        width: 100%;
      }
    }
  }

  .header-container {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 4px !important;
    cursor: default;
    font-size: 12px;
    color: #e6e7e8;
    letter-spacing: 0;
    border-bottom: 2px solid #013c6b;

    .indices-container {
      width: 45%;
      margin-right: 5%;
      box-sizing: border-box;
    }
    .spark-chart {
      width: 50%;
    }
    .alignRight {
      text-align: right;
    }
  }

  .today-time {
    text-align: right;
    width: 100%;
    font-size: 12px;
    color: #e6e7e8;
    letter-spacing: 0;
    text-align: right;
    margin-top: 10px;
  }
}
/*IPAD*/
@media only screen and (max-device-width: 1024px) {
  
}
/*MOBILE*/
@media only screen and (min-device-width: 350px) and (max-device-width: 767px) {
  
}
