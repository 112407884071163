.create-watchlist-container {
  font-family: Open Sans;
  width: 100%;
  height: inherit;
  background: #FFFFFF;

  .create-watchlist-header {
    font-size: 16px;
    font-weight: 600;
    color: #003057;
    letter-spacing: 0;
    margin-bottom: 3%;
  }
  .create-watchlist-input {
    margin-bottom: 4%;
    width: 100%;
    input {
      font-family: Open Sans;
      font-size: 14px;
      color: #555B63;
      background: #FFFFFF;
      border: 1px solid #AAADB1;
      border-radius: 3px;
      width: 100%;
      height: 40px;
      padding-left: 10px;
      box-sizing: border-box;
    }
    input::-ms-clear {
      display: none;
    }
    .already-exists {
      padding-top: 1%;
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 600;
      color: #CB223C;
      word-break: break-word;
    }
  }
  .create-watchlist-footer {
    text-align: right;
    button {
      box-shadow: 1px 2px 3px 0 rgba(85,91,99,0.20);
      border-radius: 3px;
      font-size: 14px;
      font-weight: 400;
      height: 40px;
      padding: 0 25px;
    }
    button:nth-child(1) {
      background: #FFFFFF;
      border: 1px solid #E6E7E8;
      color: #555B63;
      margin-right: 3%;
    }
    button:nth-child(2) {
      background: #003057;
      color: #FFFFFF;
      border: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .create-watchlist-container {
    .create-watchlist-input {
      .already-exists {
        font-size: 12px;
      }
      input {
        font-size: 16px;
      }
    }
  }
}