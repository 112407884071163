.news-container{
    width: 100%;
    height: 100%;
    position: relative;
    font-family: "Open sans";
    overflow: visible;
    min-height: 400px;

    .no-news{
        height: 40%;
        width: 100%;
        display: flex;
        background-color: #f7f7f7;
        align-items: center;
        justify-content: center;
        color: #7d7d7d;
        text-align: center;
        font-size: 25px;
        border-radius: 5px;
    }

    .top-4-news-container{
        background-color: inherit;
        background-color: inherit;
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
    }

    .show-more-btn-container {
        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;                              

        .show-more-btn {
            background: #2472b2;
            border-radius: 3px;
            width: 120px;
            height: 35px;
            font-size: 14px;
            color: #FFFFFF;
            font-weight: 500;
            cursor: pointer;
            border: none;
            .icon{
                display: inline-block;
                width: 6px;
                height: 6px;
                margin-right: 5px;
                border-top: 2px solid white;
                border-right: 2px solid white;
                position: relative;
                top: -3px;
                left: 6px;
               
            }

            .up{
                top: 1px;
                transform: rotate(-45deg);
            }

            .down{
                transform: rotate(135deg);                       
            }
        }
    }
}

 /*MOBILE*/
 @media only screen and (max-width : 768px) {
    .news-container{
        height: 100%;
        position: relative;
        font-family: "Open sans";
        padding: 15px;
        width: auto;
        margin-bottom: 10px;
        min-height: 100px;

        .show-more-btn-container {
            text-align: center;
            margin-top: 12px;
            margin-bottom: 20px;
            width: 100%;
        }
    
        .data-as-of-container{
            font-size: 12px;
            color: #555B63;
            margin-top: -5px;
        }

        .top-news-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}

/*IPAD*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .news-container{
        overflow-x: hidden;
    }
}